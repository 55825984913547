import React from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { withFormik } from 'formik';
import moment from 'moment';

import BookingForm from './BookingForm';
import styles from './addnewbooking.module.css';


function AddNewBooking(props) {
  
  const guestList = useSelector(({guests}) => guests && guests.guestList);
  const rentalList = useSelector(({rentals}) => rentals && rentals.rentals);

  const findRentalDetail = (rentalId) => {
    const findRental = rentalList.find((rental) => Number(rental.id) === Number(rentalId));
    return findRental || { id: '', maxOccup: 1, maxChild: 0, checkinTime: '', checkoutTime: '' };
  };

  const currentRental = findRentalDetail(props.newBookingFromEmptyNight?.rentalId);

  const initialValues = {

    // Booking - initialValues
    bookingType: "booking",
    title : "",
    rentalId: (props.newBookingFromEmptyNight?.rentalId || ""),
    totalGuests: 1,
    adult: 1,
    children: 0,
    babies: 0,
    arrive: (props.newBookingFromEmptyNight?.arrive || ""),
    depart: "",
    checkInTime: (props.newBookingFromEmptyNight && currentRental.checkinTime || ""),
    checkOutTime: (props.newBookingFromEmptyNight && currentRental.checkoutTime || ""),
    nights: 0,
    status: "Booked",
    source: "Beehaz",
    feesAndTaxes: 0,
    discount: 0,
    extraCharge: 0,
    price: 0,
    finalPrice: 0,
    paymentStatus: "Payment Pending",
    miniNotes: "",
    notes: "",
    color: "#d3d3d3",

    // Guest - initialValues
    name: "",
    emailId: "",
    secondaryEmailId: "",
    phoneNo: "",
    secondaryPhoneNo: "",
    birthday: "",
    guestType: "guest",
    postalCode: "",
    country: "",
    address: "",
    street: "",
    state: "",
    nationality: "",
    language: "",
    company: "",
    guestNotes: "",
    dob: "",

  };

  const handleSubmit = (data) => {

    const payloadBooking = {
      bookingType: "booking",
      title : `${data.name} | ${data.paymentStatus} | Direct Booking`,
      rentalId: Number(data.rentalId),
      noOfGuests: Number(data.totalGuests),
      noOfAdults: Number(data.adult),
      noOfChildren: Number(data.children),
      noOfBabies: Number(data.babies),
      arrive: moment(data.arrive).utc(true).format("YYYY-MM-DD"),
      depart: moment(data.depart).utc(true).format("YYYY-MM-DD"),
      arriveUTC : moment(data.arrive).utc(true).format("YYYY-MM-DD HH:mm:ss"),
      departUTC : moment(data.depart).utc(true).format("YYYY-MM-DD HH:mm:ss"),
      checkInTime: data.checkInTime,
      checkOutTime: data.checkOutTime,
      nights: data.nights,
      status: data.status,
      source: data.source,
      fees: Number(Number(data.fees).toFixed(2)),
      tax: Number(Number(data.taxes).toFixed(2)),
      extraGuestFee: Number(Number(data.extraGuestFee).toFixed(2)),
      baseRate: Number(Number(data.baseRate).toFixed(2)),
      discountName: "Discount",
      discountAmount: Number(Number(data.discount).toFixed(2)),
      chargeName: "Extra charge",
      chargeAmount: Number(Number(data.extraCharge).toFixed(2)),
      price: Number(Number(data.finalPrice).toFixed(2)),
      paymentStatus: data.paymentStatus,
      miniNotes: data.miniNotes,
      notes: data.notes,
      color: data.color,
    }

    let payloadGuest = {};
    const isNewGuest = data.selectedGuest?.id ? false : true;

    if (isNewGuest) {

      payloadGuest = {
        guestType: "guest",
        name: data.name,
        emailId: data.emailId,
        secondaryEmailId: data.secondaryEmailId ?? "",
        phoneNo: data?.phoneNo.split(" ")?.length !==1 ? data.phoneNo : "",
        secondaryPhoneNo: data?.secondaryPhoneNo.split(" ")?.length !==1 ? data.secondaryPhoneNo : "",
        postalCode: data.postalCode ?? "",
        address: data.address ?? "",
        street: data.street ?? "",
        state: data.state ?? "",
        country: data.country ?? "",
        nationality: data.nationality ?? "",
        language: data.language ?? "",
        company: data.company ?? "",
        notes: data.guestNotes ?? "",
        dob: data.dob ? moment(data.dob).format("YYYY-MM-DD") : "",
      };
      
    } else {

      payloadGuest = {
        guestId: data.selectedGuest.id
      };

    };

    // console.log(payloadBooking);
    // console.log(payloadGuest);
    // console.log(isNewGuest);

    props.handleSave(payloadBooking, payloadGuest, isNewGuest);
  };

  const FormikEnhancer = withFormik({
    displayName: 'BookingForm',
    mapPropsToValues: () => (initialValues),
    validateOnBlur: false,
    validateOnChange: false,
    handleSubmit:  (values, { setSubmitting }) => {
        handleSubmit(values);
        setSubmitting(false);
    },
  })(BookingForm);

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title className={styles.modalTitle}>Add new booking</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormikEnhancer
            guests={guestList}
            rentals={rentalList}
            newBookingFromEmptyNight={props.newBookingFromEmptyNight}
        />
      </Modal.Body>
    </>
  );
}

export default AddNewBooking;
