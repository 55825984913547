import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ReactTooltip from 'react-tooltip';

import MultiSelect from "./MultiSelect";
import styles from "../../rates.module.css";


function DefaultChangeForm({ schema, options, handleSubmit, onHide, initValue}) {
  
  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);

  const [selectedRates, setSelectedRates] = useState(initValue?.multiRentals ?? []);
  const [buttonText, setButtonText] = useState('Save');
  const [warning, setWarning] = useState(false);
  const [count,setCount]=useState(0);
  
  const currencyVal=rentals && rentals.length!==0 && rentals[0].currency;

  const confirmSubmit = (confirm) => {
    if (confirm) {
      setButtonText('Confirm');
      setWarning(true);
    } else {
      setButtonText('Save');
      setWarning(false);
    }
  }

  const onSubmit = (data) => {

    if (!warning && data.checkDailyRate && Number(data.dailyRate) === 0) {
      confirmSubmit(true);
    } else {
      if(selectedRates && selectedRates.length !== 0){
        handleSubmit(data,selectedRates);
      }
      // console.log(data);
      confirmSubmit(false);
    }
  }

  const sameCurrency=(row)=>{
    return row.currency===currencyVal;
  }

  function diffCurrencyWarning() {
    if (!rentals.every(sameCurrency)) {
      setCount(count+1);
    }
  }

  const resetForm=(handleReset)=>{
    handleReset();
    confirmSubmit(false);
    setSelectedRates([]);
    onHide();
  }

  useEffect( () => {
    count === 1 && toast.warn(`Different currencies used in different rentals. Check the changes.`
        ,{
          style:{
            color:'#484848'
          }
        });
  }, [count]);

  return (
    <>
      <Formik
        validationSchema={schema}
        initialValues={initValue ?? {
          multiRentals: [],
          dailyRate: '',
          maximumStayRequirement: '',
          minimumStayRequirement: '',
          blockDateBefore: '',
          blockDateAfter: '',
        }}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ errors, values, initialValues, handleReset, handleChange, setFieldValue, handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Row>
                <p className={styles.subTitleModal}>
                  Select the fields you would like to change by clicking on the checkbox to the right.
                </p>
                <Form.Label className={`col-md-5 col-form-label ${styles.form_label}`}>Select rental*: </Form.Label>
                  <div className={"col-md-5"}>
                      <MultiSelect
                        options={options}
                        value={selectedRates}
                        name="multiRentals"
                        onChange={(selectedOptions) => {
                          const selectedIds = selectedOptions.map(option => option.value);
                          setFieldValue('multiRentals', selectedIds);
                          setSelectedRates(selectedOptions);
                        }}
                        onBlur={diffCurrencyWarning}
                        placeholder={"---Select Rental---"}
                        fieldHasError={!!errors.multiRentals}
                        disabled = {initValue ?? false}
                      />
                    <div style={{fontSize:'0.8em',color:'#dc3545',padding:'0em'}}>{errors.multiRentals}</div>
                  </div>
            </Form.Row>
            <br/>
            <Form.Row>
              <Form.Label className={`col-md-5 col-form-label ${styles.form_label}`}>
                Set default rate:
              </Form.Label>
              <section className="col-md-5">
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    name="dailyRate"
                    value={values.dailyRate}
                    onChange={handleChange}
                    isInvalid={!!errors.dailyRate}
                    disabled={!values.checkDailyRate}
                    className={styles.form_entry}
                  />
                  <div className="ml-3">
                    <Form.Check
                      type="checkbox"
                      id="checkDailyRate"
                      name="checkDailyRate"
                      checked={values.checkDailyRate}
                      onChange={() => {
                        setFieldValue('dailyRate', (values.dailyRate != undefined ? values.dailyRate : initialValues.dailyRate));
                        setFieldValue('checkDailyRate', !values.checkDailyRate);
                        if (values.checkDailyRate == true) {
                          setFieldValue('dailyRate', initialValues.dailyRate);
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.dailyRate}</div>
              </section>
            </Form.Row>
            <br />
            <Form.Row>
              <Form.Label className={`col-md-5 col-form-label ${styles.form_label}`}>
                Set minimum stay:
              </Form.Label>
              <section className="col-md-5">
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    name="minimumStayRequirement"
                    value={values.minimumStayRequirement}
                    onChange={handleChange}
                    isInvalid={!!errors.minimumStayRequirement}
                    disabled={!values.checkMinimumStayRequirement}
                    className={styles.form_entry}
                  />
                  <label className={`d-none d-md-block ${styles.nightsLabel}`}>nights</label>
                  <div className="ml-3">
                    <Form.Check
                      type="checkbox"
                      id="checkMinimumStayRequirement"
                      name="checkMinimumStayRequirement"
                      checked={values.checkMinimumStayRequirement}
                      onChange={() => {
                        setFieldValue('minimumStayRequirement', (values.minimumStayRequirement != undefined ? values.minimumStayRequirement : initialValues.minimumStayRequirement));
                        setFieldValue('checkMinimumStayRequirement', !values.checkMinimumStayRequirement);
                        if (values.checkMinimumStayRequirement === true) {
                          setFieldValue('minimumStayRequirement', '');
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.minimumStayRequirement}</div>
              </section>
              <label className={`d-md-none ${styles.nightsLabel}`}>nights</label>
            </Form.Row>
            <br />
            <Form.Row>
              <Form.Label className={`col-md-5 col-form-label d-flex ${styles.form_label}`}>
                Set maximum stay:
                <div className={styles.toolTipIconContainer}>
                  <InfoOutlinedIcon
                    fontSize="small"
                    color="inherit"
                    data-tip
                    data-for="SetMaximumStay"
                    className={styles.toolTipIcon}
                  />
                  <ReactTooltip place="bottom" type="dark" id="SetMaximumStay" effect="solid" className={styles.toolTip}>
                    <span>
                      Set to 0 to use the maximum limit set in the calendar settings.
                    </span>
                  </ReactTooltip>
                </div>
              </Form.Label>
              <section className="col-md-5">
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    name="maximumStayRequirement"
                    value={values.maximumStayRequirement}
                    onChange={handleChange}
                    isInvalid={!!errors.maximumStayRequirement}
                    disabled={!values.checkMaximumStayRequirement}
                    className={styles.form_entry}
                  />
                  <label className={`d-none d-md-block ${styles.nightsLabel}`}>nights</label>
                  <div className="ml-3">
                    <Form.Check
                      type="checkbox"
                      id="checkMaximumStayRequirement"
                      name="checkMaximumStayRequirement"
                      checked={values.checkMaximumStayRequirement}
                      onChange={() => {
                        setFieldValue('maximumStayRequirement', (values.maximumStayRequirement != undefined ? values.maximumStayRequirement : initialValues.maximumStayRequirement));
                        setFieldValue('checkMaximumStayRequirement', !values.checkMaximumStayRequirement);
                        if (values.checkMaximumStayRequirement === true) {
                          setFieldValue('maximumStayRequirement', '');
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.maximumStayRequirement}</div>
              </section>
              <label className={`d-md-none ${styles.nightsLabel}`}>nights</label>
            </Form.Row>
            <br />
            <Form.Row>
              <Form.Label className={`col-md-5 col-form-label ${styles.form_label}`}>
                Block nights before:
              </Form.Label>
              <section className="col-md-5">
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    name="blockDateBefore"
                    value={values.blockDateBefore}
                    onChange={handleChange}
                    isInvalid={!!errors.blockDateBefore}
                    disabled={!values.checkBlockDateBefore}
                    className={styles.form_entry}
                  />
                  <label className={`d-none d-md-block ${styles.nightsLabel}`}>nights</label>
                  <div className="ml-3">
                    <Form.Check
                      type="checkbox"
                      id="checkBlockDateBefore"
                      name="checkBlockDateBefore"
                      checked={values.checkBlockDateBefore}
                      onChange={() => {
                        setFieldValue('blockDateBefore', (values.blockDateBefore != undefined ? values.blockDateBefore : initialValues.blockDateBefore));
                        setFieldValue('checkBlockDateBefore', !values.checkBlockDateBefore);
                        if (values.checkBlockDateBefore === true) {
                          setFieldValue('blockDateBefore', '');
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.blockDateBefore}</div>
              </section>
              <label className={`d-md-none ${styles.nightsLabel}`}>nights</label>
            </Form.Row>
            <br />
            <Form.Row>
              <Form.Label className={`col-md-5 col-form-label ${styles.form_label}`}>
                Block nights after:
              </Form.Label>
              <section className="col-md-5">
                <div className="d-flex align-items-center">
                  <Form.Control
                    type="text"
                    name="blockDateAfter"
                    value={values.blockDateAfter}
                    onChange={handleChange}
                    isInvalid={!!errors.blockDateAfter}
                    disabled={!values.checkBlockDateAfter}
                    className={styles.form_entry}
                  />
                  <label className={`d-none d-md-block ${styles.nightsLabel}`}>nights</label>
                  <div className="ml-3">
                    <Form.Check
                      type="checkbox"
                      id="checkBlockDateAfter"
                      name="checkBlockDateAfter"
                      checked={values.checkBlockDateAfter}
                      onChange={() => {
                        setFieldValue('blockDateAfter', (values.blockDateAfter != undefined ? values.blockDateAfter : initialValues.blockDateAfter));
                        setFieldValue('checkBlockDateAfter', !values.checkBlockDateAfter);
                        if (values.checkBlockDateAfter === true) {
                          setFieldValue('blockDateAfter', '');
                        }
                      }}
                    />
                  </div>
                </div>
                <div className={`${styles.errorMessage}`} >{errors.blockDateAfter}</div>
              </section>
              <label className={`d-md-none ${styles.nightsLabel}`}>nights</label>
            </Form.Row>
            <br />
            {warning &&
                <p className={styles.warningMessage}>
                    The rate was set to 0. Are you sure you want to proceed with saving the changes?
                </p>
            }
            <div className='p-2 d-flex justify-content-center'>
                <div className='col-md-8 d-flex justify-content-around'>
                    <button  type="submit" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} >{buttonText}</button>
                    <button type="reset" className={`col-lg-5 p-1 mx-2 col-sm-5 ${styles.allBtn}`} style={{backgroundColor:"#6c757d"}} onClick={()=>resetForm(handleReset)} >Discard</button>
                </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default DefaultChangeForm;
