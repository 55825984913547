import React, { useEffect, useState } from 'react';
import {useSelector,useDispatch} from "react-redux";
import { Row, Col } from 'react-bootstrap';
import * as yup from 'yup';
import { Form, Field } from 'formik';
import moment from 'moment';
import Select from "react-select";
import PhoneInput from 'react-phone-input-2';
import countryList from "react-select-country-list";
import {toast} from "react-toastify";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import {chargeCalculation,resetCharges} from '../../../../../../general_redux/calendar/actions';
import { findCountry } from '../../../../../../common/functions/utils';
import CustomDate from '../../../../../../components/CustomDate';
import styles from './addnewbooking.module.css';


function BookingForm(props) {
  
  const dispatch = useDispatch();
  
  const {
    values,
    setFieldValue,
    handleChange,
    handleSubmit,
    newBookingFromEmptyNight,
    rentals,
    guests,
  } = props;

  
  const userCountry= useSelector(({generalSetting}) => generalSetting && generalSetting.setting && generalSetting.setting.country);
  const ratesList = useSelector(({rates}) => rates && rates.rateSettings);
  const currentCharges = useSelector(({calendar}) => calendar && calendar.currentCharges);
  const { numberOf, allowBookingFor } = useSelector(({generalSetting}) => generalSetting && generalSetting.setting);

  const findRentalDetail = (rentalId) => {
    const findRental = rentals.find((rental) => Number(rental.id) === Number(rentalId));
    return findRental || { id: '', maxOccup: 1, maxChild: 0, checkinTime: '', checkoutTime: '' };
  };

  const [guestDetail, setGuestDetail] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(false);
  const [secondaryPhoneNumber, setSecondaryPhoneNumber] = useState(false);
  const [country, setCountry] = useState([]);
  const [errors, setErrors] = useState({});
  const [userCode] = useState(findCountry(userCountry));
  const [rentalDetail, setRentalDetail] = useState(findRentalDetail(newBookingFromEmptyNight?.rentalId || ''));

  const paymentOpt = ["Payment Pending", "Partially Paid", "Paid"];
  
  const colorDictionary = useSelector(({ generalSetting }) => {
    const colorsSetting = generalSetting && generalSetting.setting.colors;
    const map = [];
    for (const key in colorsSetting) {
        if (key.startsWith('color')) {
            map.push(colorsSetting[key]);
        }
    };
    return map;
  });

  const compare = (a, b) => {
    return a.label > b.label ? 1 : b.label > a.label ? -1 : 0;
  };

  const getChannelOptions = (channels) => {
    const channelOptions = channels.map((item) => {
      const ChannelNameObject = getChannelName(item.channel);
      return {label: ChannelNameObject.label, value: ChannelNameObject.value};
    });
    return [{label: 'Beehaz', value: 'Beehaz'}, ...channelOptions];
  }; 

  const getChannelName = (channel) => {
    if (channel.ota === 'Other')
        return {label: `${channel.name} (iCal)`, value: channel.name}
    if (channel.ota === 'VRBO')
        return {label: 'VRBO (HomeAway) (iCal)', value: channel.ota}
    else
        return {label: `${channel.ota} (iCal)`, value: channel.ota}
  };

  const findRateDetail = (rentalId) => {
    const findRate = ratesList.find((rate) => Number(rate.rentalId) === Number(rentalId));
    return findRate || { minimumStayRequirement: 10, maximumStayRequirement: 1 };
  };

  const channels = useSelector(({icalChannels}) => icalChannels && getChannelOptions(icalChannels.channels));

  useEffect(()=>{
    
    const rateDetail = findRateDetail(newBookingFromEmptyNight?.rentalId || '');
    setFieldValue('minStay', rateDetail.minimumStayRequirement);
    setFieldValue('maxStay', rateDetail.maximumStayRequirement);

    return()=>{
      dispatch(resetCharges());
    }
  }, [])

  useEffect( () => {

    if (values.rentalId && ratesList) {

      rentals.map((rental) => {
        if(rental.id === Number(values.rentalId)){
          setRentalDetail(rental);
          setFieldValue('checkInTime', rental.checkinTime);
          setFieldValue('checkOutTime', rental.checkoutTime);
        }
      });

    };

  }, [values.rentalId]);

  useEffect(() => {

    if(values.rentalId && values.arrive && values.depart && values.totalGuests && (values.adult > 0)){

      dispatch(chargeCalculation({
        bookingType: "booking",
        rentalId: Number(values.rentalId),
        arrive: moment(values.arrive).format("YYYY-MM-DD"),
        depart: moment(values.depart).format("YYYY-MM-DD"),
        noOfAdults: Number(values.adult),
        noOfChildren: Number(values.children),
        discount: Number(values.discount),
        extraCharge: Number(values.extraCharge),
      }))

      setFieldValue('nights', moment(values.depart).diff(moment(values.arrive), 'days'));
      setDisableSubmit(true);

    };

  }, [
    values.rentalId,
    values.arrive,
    values.depart,
    values.adult,
    values.children,
    values.extraCharge,
    values.discount,
    dispatch,
  ]);

  useEffect(() => {

    if (currentCharges) {
      setFieldValue('baseRate', Number(currentCharges.daily_rate).toFixed(2));
      setFieldValue('extraGuestFee', Number(currentCharges.extra_guest_fees).toFixed(2));
      setFieldValue('price', Number((currentCharges.daily_rate + currentCharges.extra_guest_fees).toFixed(2)));
      setFieldValue('finalPrice', Number(currentCharges.total).toFixed(2));
      setFieldValue('feesAndTaxes', Number(currentCharges.fees + currentCharges.taxes).toFixed(2));
      setFieldValue('fees', Number(currentCharges.fees).toFixed(2));
      setFieldValue('taxes', Number(currentCharges.taxes).toFixed(2));
    };
    
    if(currentCharges && !currentCharges.maxStay){
      setFieldValue('maxStay', currentCharges.maxStayConstrain);
    };

    if(currentCharges && !currentCharges.minStay){
      setFieldValue('minStay', currentCharges.minStayConstrain);
    };

    setDisableSubmit(false);

  }, [currentCharges])

  useEffect(()=>{

    if (guestDetail && guestDetail !== "") {

      const guestData = guests.find((guest) => (guest.name === guestDetail.label));

      setFieldValue('selectedGuest', guestData);
      setFieldValue('name', guestData.name);
      setFieldValue('emailId', guestData.emailId);
      setFieldValue('secondaryEmailId', guestData.secondaryEmailId);
      setFieldValue('phoneNo', guestData.phoneNo);
      setFieldValue('secondaryPhoneNo', guestData.secondaryPhoneNo);
      setFieldValue('postalCode', guestData.postalCode);
      setFieldValue('country', guestData.country);
      setFieldValue('address', guestData.address);
      setFieldValue('street', guestData.street);
      setFieldValue('state', guestData.state);
      setFieldValue('nationality', guestData.nationality);
      setFieldValue('language', guestData.language);
      setFieldValue('company', guestData.company);
      setFieldValue('guestNotes', guestData.notes);

      if (guestData.dob && ["Invalid da", "", null, "[NULL]"].includes(!guestData.dob)) {
        setFieldValue('dob', new Date(guestData.dob));
      } else {
        setFieldValue('dob', '');
      };

    } else {
      setFieldValue('selectedGuest', false);
      setFieldValue('name', '');
      setFieldValue('emailId', '');
      setFieldValue('secondaryEmailId', '');
      setFieldValue('phoneNo', '');
      setFieldValue('secondaryPhoneNo', '');
      setFieldValue('postalCode', '');
      setFieldValue('country', '');
      setFieldValue('address', '');
      setFieldValue('street', '');
      setFieldValue('state', '');
      setFieldValue('nationality', '');
      setFieldValue('language', '');
      setFieldValue('company', '');
      setFieldValue('guestNotes', '');
      setFieldValue('dob', '');
    };

  }, [guestDetail])

  const guestForm = [
    { title: 'Name*: ',               name: 'name',                 type: 'text' },
    { title: 'Company: ',             name: 'company',              type: 'text' },
    { title: 'Email address*: ',      name: 'emailId',              type: 'text' },
    { title: 'Secondary email: ',     name: 'secondaryEmailId',     type: 'text' },
    { title: 'Phone: ',               name: 'phoneNo',              type: 'text' },
    { title: 'Secondary phone: ',     name: 'secondaryPhoneNo',     type: 'text' },
    { title: 'Country: ',             name: 'country',              type: 'text' },
    { title: 'Street and nr: ',       name: 'street',               type: 'text' },
    { title: 'Postal code: ',         name: 'postalCode',           type: 'text' },
    { title: 'State/Province: ',      name: 'state',                type: 'text' },
    { title: 'Address details: ',     name: 'address',              type: 'text' },
    { title: 'Nationality: ',         name: 'nationality',          type: 'text' },
    { title: 'Language: ',            name: 'language',             type: 'text' },
    { title: 'Birthday:',             name: 'dob',                  type: 'date' },
    { title: 'More info: ',           name: 'guestNotes',           type: 'text', isIcon: true, iconContent: 'Write more information or notes specific to your guest here. These can be retrieved later if the guest makes another reservation.' },
  ];

  const validationSchema = yup.object().shape({

    // Booking validation schema
    rentalId: yup.number().required('Rental selection is required').integer('Must be an integer').typeError('Rental selection is required'),
    totalGuests: yup.number().required('At least 1 guest is required').min(1, 'Must be a number greater than or equal to 1').integer('Must be an integer').typeError('Must be a value of type number')
        .test('totalGuests', `Maximum ${rentalDetail.maxOccup} guests are allowed`, (value) => {
            return (value > rentalDetail.maxOccup) ? false : true;
    }),
    adult: yup.number().required('At least 1 adult is required').min(1, 'At least 1 adult is required').integer('Must be an integer').typeError('Must be a value of type number'),
    children: yup.number().min(0, 'Must be a positive number').integer('Must be an integer').typeError('Must be a value of type number')
        .test('children', `Maximum ${rentalDetail.maxChild} children are allowed`, (value) => {
            return (value > rentalDetail.maxChild) ? false : true;
    }),
    babies: yup.number().min(0, 'Must be a positive number').integer('Must be an integer').typeError('Must be a value of type number'),
    arrive: yup.date().required('Arrival date is required').typeError('Must be a value of type date').max(`${moment().add(2,'years').format('YYYY-MM-DD')}`, 'Booking not allowed after 2 years'),
    depart: yup.date().required('Departure date is required').typeError('Must be a value of type date').max(`${moment().add(2,'years').format('YYYY-MM-DD')}`, 'Booking not allowed after 2 years'),
    checkInTime: yup.string().required('Check-In is required'),
    checkOutTime: yup.string().required('Check-Out is required'),
    nights: yup.number().min(0, 'Must be a positive number').integer('Must be an integer').typeError('Must be a value of type number')
      .test('nights', function (value) {
        let message;
        const minStay = this.parent.minStay;
        const maxStay = this.parent.maxStay;
        if (value < minStay) {
          message = `Minimum stay for this rental is ${minStay} day${minStay > 1 ? 's' : ''}`;
        } else if (value > maxStay && maxStay > 0) {
          message = `Maximum stay for this rental is ${maxStay} day${maxStay > 1 ? 's' : ''}`;
        };
        return !message ? true : this.createError({
          path: this.path,
          message: message,
        });
    }),
    source: yup.string(),
    paymentStatus: yup.string(),
    finalPrice: yup.number().min(0, 'Must be a positive number').typeError('Must be a value of type number'),
    miniNotes: yup.string().max(50, 'Maximum 50 characters are allowed'),
    notes: yup.string().max(150, 'Maximum 150 characters are allowed'),
    color: yup.string(),

    // Guest validation schema
    name: yup.string().required('Guest name is required').max(100, 'Must be 100 characters or less'),
    emailId: yup.string().email('Invalid email address').required('Email address is required').max(100, 'Must be 100 characters or less'),
    secondaryEmailId: yup.string().email('Invalid email address').max(100, 'Must be 100 characters or less'),
    phoneNo: yup.string().max(50, 'Must be 50 characters or less'),
    secondaryPhoneNo: yup.string().max(50, 'Must be 50 characters or less'),
    guestType: yup.string().max(50, 'Must be 50 characters or less'),
    postalCode: yup.string().max(50, 'Must be 50 characters or less'),
    country: yup.string().max(50, 'Must be 50 characters or less'),
    address: yup.string().max(100, 'Must be 100 characters or less'),
    street: yup.string().max(100, 'Must be 100 characters or less'),
    state: yup.string().max(100, 'Must be 100 characters or less'),
    nationality: yup.string().max(100, 'Must be 100 characters or less'),
    language: yup.string().max(50, 'Must be 50 characters or less'),
    company: yup.string().max(100, 'Must be 100 characters or less'),
    guestNotes: yup.string().max(100, 'Must be 100 characters or less'),
    dob: yup.string().nullable().typeError('Must be a value of type date'),

  });

  const onSubmiter = (e) => {
    e.preventDefault();

    validationSchema
      .validate(values, {  abortEarly: false })
      .then(() => {
          handleSubmit(e);
      })
      .catch((err) => {
        const errorDetails = err.inner.reduce((acc, error) => {
            acc[error.path] = error.message;
            return acc;
        }, {});
        setErrors(errorDetails);
    });

  };


  return (
                <Form onSubmit={onSubmiter}>
                  <Row>
                    <Col lg={6} className={styles.wrapper}>
                      <h3 className={styles.header}>Booking Details</h3>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"selectRental"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Select rental*: "}</div>
                        <div className={`w-100`}>
                          <Field
                              as={"select"}
                              name={"rentalId"}
                              value={values.rentalId}
                              className={(errors.rentalId)?styles.form_entry_err:styles.form_entry}
                              onChange={(e)=> {
                                const currentRental = findRentalDetail(e.target.value);
                                const rateDetail = findRateDetail(e.target.value);
                                setFieldValue('rentalId', currentRental.id);
                                setFieldValue('checkInTime', currentRental.checkinTime);
                                setFieldValue('checkOutTime', currentRental.checkoutTime);
                                setFieldValue('minStay', rateDetail.minimumStayRequirement);
                                setFieldValue('maxStay', rateDetail.maximumStayRequirement);
                                setRentalDetail(currentRental);
                              }}
                          >
                            <option value={0} label={"---Select Rental---"}/>
                            {rentals.map((opt)=>(
                                <option key={opt.id} value={opt.id}>{opt.name}</option>
                            ))}
                          </Field>
                          <div className={errors.rentalId ? styles.form_error : styles.form_error_hidden}>
                            {errors.rentalId}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"totalGuest"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Number of guests: "}</div>
                        <div className={`w-100`}>
                          <Field
                              type={"text"}
                              name={"totalGuests"}
                              value={values.totalGuests}
                              className = {(errors.totalGuests)?styles.form_entry_err:styles.form_entry}
                              disabled={true}
                          />
                          <div className={errors.totalGuests ? styles.form_error : styles.form_error_hidden}>
                            {errors.totalGuests}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"adult"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Adults: "}</div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"adult"}
                            value={values.adult}
                            className={(errors.adult)?styles.form_entry_err:styles.form_entry}
                            onChange={(e)=> {
                              const adultValue = e.target.value;
                              setFieldValue('adult', adultValue);
                              setFieldValue('totalGuests', Number(adultValue)+Number(values.children));
                            }}
                        />
                          <div className={errors.adult ? styles.form_error : styles.form_error_hidden}>
                              {errors.adult}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"children"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Children: "}</div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"children"}
                            value={values.children}
                            className={(errors.children)?styles.form_entry_err:styles.form_entry}
                            onChange={(e)=> {
                              const childrenValue = e.target.value;
                              setFieldValue('children', childrenValue);
                              setFieldValue('totalGuests', Number(childrenValue)+Number(values.adult));
                            }}
                        />
                          <div className={errors.children ? styles.form_error : styles.form_error_hidden}>
                              {errors.children}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"babies"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {"Babies: "}
                          <InfoOutlinedIcon
                            fontSize="small"
                            color="inherit"
                            data-tip
                            data-for="babies"
                            className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="babies" effect="solid" className={styles.toolTip}>
                            <span>
                              Babies are not counted in the number of total guests (which consists of the total number of adults + the total number of children). This means they do not have any effect on the price calculation (while the number of adults and children influence the price when you have per person fees in your pricing).
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"babies"}
                            value={values.babies}
                            className={(errors.babies)?styles.form_entry_err:styles.form_entry}
                            onChange={handleChange}
                        />
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"arrive"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Arrival date*: "}</div>
                        <div className={`w-100`}>
                          <CustomDate
                              id={"arrive"}
                              name={"arrive"}
                              value={values.arrive}
                              handleChange={(date) => setFieldValue('arrive', date)}
                              hasError={errors.arrive}
                              customClass={errors.arrive ? styles.form_entry_err : styles.form_entry}
                              max={(values.depart) ? values.depart:new Date(`${moment().add(numberOf, allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                          />
                          <div className={errors.arrive ? styles.form_error : styles.form_error_hidden}>
                            {errors.arrive}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"depart"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Departure date*: "}</div>
                        <div className={`w-100`}>
                          <CustomDate
                              id={"depart"}
                              name={"depart"}
                              value={values.depart}
                              handleChange={(date) => setFieldValue('depart', date)}
                              hasError={errors.depart}
                              customClass={errors.depart ? styles.form_entry_err : styles.form_entry}
                              min={values.arrive}
                              max={new Date(`${moment().add(numberOf, allowBookingFor === "months" ? 'M' : 'y').format('YYYY-MM-DD')}`)}
                          />
                          <div className={errors.depart ? styles.form_error : styles.form_error_hidden}>
                            {errors.depart}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"nights"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Total nights: "}</div>
                        <div className={`w-100`}>
                        <Field
                          type={"text"}
                          name={"nights"}
                          value={values.nights}
                          disabled={true}
                          className={ errors.nights ? styles.form_entry_err : styles.form_entry }
                        />
                        <div className={errors.nights ? styles.form_error : styles.form_error_hidden}>
                          {errors.nights}
                        </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"priceInfoIcon"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Price: "}
                            <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="priceInfoIcon"
                              className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="priceInfoIcon" effect="solid" className={styles.toolTip}>
                              <span>
                                The price takes into account the rates set for these dates and extra guest fees. These can be
                                managed by clicking on the Rates icon. You can manually overwrite the total for this booking by
                                editing the booking later.
                              </span>
                            </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                          <Field
                              type={"text"}
                              name={"price"}
                              className={(errors.price)?styles.form_entry_err: styles.form_entry}
                              value={Number(values.price).toFixed(2)}
                              disabled={true}
                          />
                          <div className={errors.price ? styles.form_error : styles.form_error_hidden}>
                            {errors.price}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"discount"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Discount:'}
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"discount"}
                            className={(errors.discount)?styles.form_entry_err: styles.form_entry}
                            value={values.discount}
                            onChange={handleChange}
                        />
                        </div>
                        <div className={errors.discount ? styles.form_error : styles.form_error_hidden}>
                          {errors.discount}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"extraCharge"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Extra charge:'}
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"extraCharge"}
                            value={values.extraCharge}
                            className={(errors.extraCharge)?styles.form_entry_err: styles.form_entry}
                            onChange={handleChange}
                        />
                        </div>
                        <div className={errors.extraCharge ? styles.form_error : styles.form_error_hidden}>
                          {errors.extraCharge}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"feesAndTaxes"}>
                        <div className={`d-flex ${styles.form_title}`}>
                          {'Fees and taxes:'}
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"feesAndTaxes"}
                            value={Number(values.feesAndTaxes).toFixed(2)}
                            className={(errors.feesAndTaxes)?styles.form_entry_err: styles.form_entry}
                            disabled={true}
                        />
                        </div>
                        <div className={errors.feesAndTaxes ? styles.form_error : styles.form_error_hidden}>
                          {errors.feesAndTaxes}
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"finalPrice"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Final price: "}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="finalPriceIcon"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="finalPriceIcon" effect="solid" className={styles.toolTip}>
                            <span>
                             Considers rates, extra guest fees, fees, and taxes. These can be managed by clicking on the
                             Rates icon. Any discount or charge you have set above are also considered here. You can
                             manually overwrite the total for this booking by editing the booking later
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                          <Field
                              type={"text"}
                              name={"finalPrice"}
                              value={Number(values.finalPrice).toFixed(2)}
                              className={(errors.finalPrice)?styles.form_entry_err: styles.form_entry}
                              disabled={true}
                          />
                          <div className={errors.finalPrice ? styles.form_error : styles.form_error_hidden}>
                            {errors.finalPrice}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"paymentStatus"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Payment status: "}</div>
                        <div className={`w-100`}>
                        <Field
                            as={"select"}
                            name={"paymentStatus"}
                            className={(errors.paymentStatus)?styles.form_entry_err: styles.form_entry}
                        >
                          <option key={""} value={""} disabled={true} label={"--Select Status--"}/>
                          {paymentOpt.map((opt)=>(
                              <option value={opt} key={opt}>{opt}</option>
                          ))}
                        </Field>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"source:"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Source: "}</div>
                        <div className={`w-100`}>
                        <Field
                            as={'select'}
                            name={"source"}
                            value={values.source}
                            className={(errors.source)?styles.form_entry_err: styles.form_entry}
                            onChange={handleChange}
                        >
                          {channels.map((opt, index) => (
                              <option key={index} value={opt.value} >
                                {opt.label}
                              </option>
                          ))}
                        </Field>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"checkintime"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Check-in time*: "}</div>
                        <div className={`w-100`}>
                          <Field
                              type={"time"}
                              name={"checkInTime"}
                              value={values.checkInTime}
                              className={errors.checkInTime ? styles.form_entry_err : styles.form_entry}
                              onChange={handleChange}
                          />
                          <div className={errors.checkInTime ? styles.form_error : styles.form_error_hidden}>
                            {errors.checkInTime}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"checkouttime"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Check-out time*: "}</div>
                        <div className={`w-100`}>
                          <Field
                              type={"time"}
                              name={"checkOutTime"}
                              value={values.checkOutTime}
                              className={errors.checkOutTime ? styles.form_entry_err : styles.form_entry}
                              onChange={handleChange}
                          />
                          <div className={errors.checkOutTime ? styles.form_error : styles.form_error_hidden}>
                            {errors.checkOutTime}
                          </div>
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"notesBooking"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Notes:"}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="notesBooking"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="notesBooking" effect="solid" className={styles.toolTip}>
                            <span>
                              Write longer notes specific to this booking here.
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"notes"}
                            value={values.notes}
                            className={styles.form_entry}
                            onChange={handleChange}
                        />
                        </div>
                      </div>
                      <div className={`d-flex ${styles.form_wrapper}`} key={"miniNotes"}>
                        <div className={`d-flex ${styles.form_title}`}>{"Mini notes:"}
                          <InfoOutlinedIcon
                              fontSize="small"
                              color="inherit"
                              data-tip
                              data-for="miniNotes"
                              className={styles.toolTipIcon}
                          />
                          <ReactTooltip place="bottom" type="dark" id="miniNotes" effect="solid" className={styles.toolTip}>
                            <span>
                              Write a short note to be displayed in the booking information summary box that appears when
                              you click on a reservation in the calendar.
                            </span>
                          </ReactTooltip>
                        </div>
                        <div className={`w-100`}>
                        <Field
                            type={"text"}
                            name={"miniNotes"}
                            value={values.miniNotes}
                            className={`${styles.form_entry}`}
                            onChange={handleChange}
                        />
                        </div>
                      </div>
                    </Col>
                    <Col s={12} lg={6} className={styles.wrapper}>
                      <h3 className={styles.header}>Guest Details</h3>
                      <div className={`${styles.searchContainer}`}>
                        <Select
                          className='w-100'
                          options={guests.map((row) => ({label: row.name, value: row.id})).sort(compare)}
                          onChange={setGuestDetail}
                          value={guestDetail}
                          isSearchable={true}
                          isClearable={true}
                          placeholder={"Search guest..."}
                        />
                        <div className={`${styles.paragraphBelowSearch} d-flex align-items-center`}>
                          <hr className={`${styles.hrClass} flex-grow-1`}/>
                          <p className='mx-3'>or add new guest</p>
                          <hr className={`${styles.hrClass} flex-grow-1`}/>
                        </div>
                      </div>
                      {guestForm.map((form) => {
                        return (
                            <div className={`d-flex ${styles.form_wrapper}`} key={form.title}>
                              {form.isIcon ? (
                                  <>
                                    <div className={`d-flex ${styles.form_title}`}>{form.title}
                                      <InfoOutlinedIcon
                                          fontSize="small"
                                          color="inherit"
                                          data-tip
                                          data-for={`${form.title}-icon`}
                                          className={styles.toolTipIcon}
                                      />
                                      <ReactTooltip place="bottom" type="dark" id={`${form.title}-icon`} effect="solid" className={styles.toolTip}>
                                    <span>
                                      {form.iconContent}
                                    </span>
                                      </ReactTooltip>
                                    </div>
                                  </>
                              ):(
                                  <div className={`d-flex ${styles.form_title}`}>{form.title}</div>
                              )}
                              {(guestDetail && guestDetail !== "") ? (
                                      (form.name==="dob" )?(
                                        <div className="w-100">
                                          <CustomDate
                                            id={"dob"}
                                            name={"dob"}
                                            readOnly={true}
                                            handleChange={(date) => setFieldValue('dob', (date ?? ''))}
                                            value={values.dob}
                                            max={new Date()}
                                            min={new Date(`${(new Date().getUTCFullYear())-100}-01-01`)}
                                            customClass={errors.dob ? styles.form_entry_err : styles.form_entry}
                                          />
                                          <div className={errors[form.name] ? styles.form_error : styles.form_error_hidden}>
                                            {errors[form.name]}
                                          </div>
                                        </div>
                                      ):(
                                        <div className="w-100">
                                          <Field
                                              id={form.name}
                                              name={form.name}
                                              readOnly={true}
                                              type={form.type}
                                              value={values[form.name]}
                                              className={styles.form_entry}
                                          />
                                          <div className={errors[form.name] ? styles.form_error : styles.form_error_hidden}>
                                            {errors[form.name]}
                                          </div>
                                        </div>
                                      )
                                  ):
                                  (<>
                                        { form.name==="country" ? (
                                            <div className="w-100">
                                              <Select
                                                  options={countryList().getData()}
                                                  name="country"
                                                  value={country}
                                                  onChange={(val) => {
                                                    setCountry(val);
                                                    setFieldValue('country', (val?.label ?? ''));
                                                  }}
                                                  isClearable={true}
                                                  className={styles.form_entry_select}
                                              />
                                            </div>
                                            ) : form.name==="phoneNo" ? (
                                                    <div className="w-100">
                                                      <PhoneInput
                                                          name="phoneNo"
                                                          country={userCode}
                                                          value={phoneNumber}
                                                          onChange={(value, data, event, formattedValue) => {
                                                            const rawVal = value.slice(data.dialCode.length);
                                                            const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                                                            setFieldValue("phoneNo", phone);
                                                            setPhoneNumber(phone || data.dialCode);
                                                          }}
                                                          inputStyle={{width: '100%'}}
                                                          countryCodeEditable={false}
                                                          enableSearch={true}
                                                          disableSearchIcon={true}
                                                      />
                                                      <div className={errors[form.name] ? styles.form_error : styles.form_error_hidden}>
                                                        {errors[form.name]}
                                                      </div>
                                                    </div>
                                            ) : form.name==="secondaryPhoneNo" ? (
                                                    <div className="w-100">
                                                      <PhoneInput
                                                          name="secondaryPhoneNo"
                                                          country={userCode}
                                                          value={secondaryPhoneNumber}
                                                          onChange={(value, data, event, formattedValue) => {
                                                            const rawVal = value.slice(data.dialCode.length);
                                                            const phone = rawVal ? `+${data.dialCode} ${rawVal}` : "";
                                                            setFieldValue("secondaryPhoneNo", phone);
                                                            setSecondaryPhoneNumber(phone || data.dialCode);
                                                          }}
                                                          inputStyle={{width: '100%'}}
                                                          countryCodeEditable={false}
                                                          enableSearch={true}
                                                          disableSearchIcon={true}
                                                      />
                                                      <div className={errors[form.name] ? styles.form_error : styles.form_error_hidden}>
                                                        {errors[form.name]}
                                                      </div>
                                                    </div>
                                            ) : form.name==="dob" ? (
                                                    <div className={`w-100`}>
                                                      <CustomDate 
                                                        id={"dob"}
                                                        name={"dob"}
                                                        handleChange={(date) => setFieldValue('dob', (date ?? ''))}
                                                        value={values.dob}
                                                        max={new Date()}
                                                        min={new Date(`${(new Date().getUTCFullYear())-100}-01-01`)}
                                                        customClass={errors.dob ? styles.form_entry_err : styles.form_entry}
                                                      />
                                                      <div className={errors[form.name] ? styles.form_error : styles.form_error_hidden}>
                                                        {errors[form.name]}
                                                      </div>
                                                    </div>
                                            ) : (
                                                    <div className={`w-100`}>
                                                      <Field
                                                          id={form.name}
                                                          name={form.name}
                                                          type={form.type}
                                                          className={errors[form.name] ? styles.form_entry_err : styles.form_entry}
                                                          value={values[form.name]}
                                                          onChange={handleChange}
                                                      />
                                                      <div className={errors[form.name] ? styles.form_error : styles.form_error_hidden}>
                                                        {errors[form.name]}
                                                      </div>
                                                    </div>
                                            )}
                                      </>
                                  )}
                            </div>
                        );
                      })}
                      <Row className={`pl-3`}>
                        <div className={`d-flex ${styles.form_title} ${styles.labelColor}`}>Choose colour: </div>
                        <div className={styles.containerColor}>
                          {colorDictionary.map((item, index)=>(
                              <div
                                  className={`${styles.containerBox} ${(values.color===item)?` active1`:``}`}
                                  style={{background:`${item}`}}
                                  onClick={() => setFieldValue('color', item)}
                                  key={index}
                              />
                          ))}
                          <Field
                              type={'color'}
                              name={'color'}
                              value={values.color}
                              onChange={handleChange}
                              className={styles.form_entry_color}
                          />
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={`mt-3 d-flex justify-content-center`}>
                        <div>
                          <button type="submit" className={styles.settings_save} disabled={disableSubmit}>
                            {'Save changes'}
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Form>
  )
};
export default BookingForm;
