import React, { useState, useEffect } from 'react';
import Toggle from 'react-toggle';
import moment from 'moment';
import './toggle-css.css';
import ConfirmMessage from '../../../../components/Confirmation';
import styles from './usersetting.module.css';
import Loading from '../../../../components/loading';
import {addUserSettings, getUserInfo, deleteUser, GetPackages,paymentSession,startSubscription} from '../../../../general_redux/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";

function UserSettings() {
  const dispatch = useDispatch();
  const [deleteModal, setDeleteModal] = useState(false);
  const user = useSelector(({ user }) => user && user.user);
  const paymentSessionAction=useSelector(({user})=>user && user.paymentSessionAction);
  const { useSettingUpdate, useSettingLoad, deleteUserAction } = useSelector(({ user }) => user);
  const rentals=useSelector(({rentals})=>rentals && rentals.rentals);
  
  const [bookingNotificationByEmail, setBookingNotificationByEmail] = useState(user?.bookingNotificationByEmail);
  const [softwareUpdateNotification, setSoftwareUpdateNotification] = useState(user?.softwareUpdateNotification);

  const perMonth=()=>{
    if(rentals.length<=3){
      return '4.50';
    }else{
      return (`${(1.5*rentals.length).toFixed(2)}`)
    }
  }

  const perYear=()=>{
    if(rentals.length<=3){
      return '54.00';
    }else{
      return `${(rentals.length*12*1.20).toFixed(2)}`;
    }
  }

  const perMonthcost=perMonth();
  const perYearcost=perYear();
  const [radioSelection,setRadioSelection]=useState({
    perMonth:false,
    perYear:false
  });

  useEffect(() => {
    dispatch(getUserInfo());
    dispatch(GetPackages());
  }, []);
  
  useEffect(() => {
    setBookingNotificationByEmail(user?.bookingNotificationByEmail);
    setSoftwareUpdateNotification(user?.softwareUpdateNotification);
  }, [user]);

  const handleShow = () => setDeleteModal(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    const userSettingsData = {
      name: e.target.name.value,
      emailId: e.target.emailId.value,
      language: e.target.language.value,
      permissions: e.target.permissions.value,
      accountType: user.accountType,
      planType: radioSelection.perYear? "yearly":"monthly",
      companyName:e.target.companyName.value,
      bookingNotificationByEmail: e.target.bookingNotificationByEmail.checked,
      softwareUpdateNotification: e.target.softwareUpdateNotification.checked,
    };
    dispatch(addUserSettings(userSettingsData));
  };
  const deleteUserAcc = () => {
    dispatch(deleteUser(user.id));
    setDeleteModal(false);
  };

  const handlePayment=(e)=>{
    e.preventDefault();
    const payload={
      customer_id:user.id,
      key:radioSelection.perMonth?"OMS":"YSB"
    }
    if(radioSelection.perMonth){
      dispatch(paymentSession(payload));
    }else{
      dispatch(startSubscription(payload));
    }
    
  }

  return (
      <div className={'p-3'}>
        <ConfirmMessage
            show={deleteModal}
            onHide={() => {
              setDeleteModal(false);
            }}
            confirmHeader={`Are you sure you want to delete your account?`}
            confirmBody={`Deleting your account is permanent and will remove all content including bookings 
            and profile settings. This action cannot be undone. 
            Are you sure you want to delete your account?`}
            onConfirmAct={deleteUserAcc}
        />
        <Loading loadingStatus={useSettingUpdate.loading ||
        useSettingLoad.loading || deleteUserAction.loading || paymentSessionAction.loading} />

        <div className={`d-flex flex-column mb-3`}>
          <div className={`${styles.usersettingHeader}`}>
            User Settings
          </div>
          <div className={styles.accNo}>
            <i>Account nr: {user && user.uuid}</i>
            <i>Since: {moment(user.createdAt).format('D MMMM YYYY')}</i>
          </div>
        </div>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="form-group row">
            <label htmlFor="name" className="col-sm-2 col-md-3 col-xl-2 col-form-label">
              Name:
            </label>
            <div className="col-md-4">
              <input type="text" className="form-control" id="name" style={{fontSize:'inherit'}} defaultValue={user.name} required />
            </div>
          </div>

          <div className="form-group row">
            <div className="pb-2 d-flex flex-direction-column col-sm-2 col-md-3 col-xl-2 col-form-label">
              <label htmlFor="emailId" className="">
                Email:
              </label>
              <div className={styles.toolTipIconContainer}>
                <InfoOutlinedIcon
                    fontSize="small"
                    color="inherit"
                    data-tip
                    data-for="emailIcon"
                    className={styles.toolTipIcon}
                />
                <ReactTooltip place="bottom" type="dark" id="emailIcon" effect="solid" className={styles.toolTip}>
                  <span>
                    You can change the email linked to your Beehaz account. Type in the new email address and click the ‘save changes’ button
                    bellow. You will then have 15 minutes to verify the account change request: please follow the instructions that will be sent to
                    you per email. If the verification of the new email address within the time limit fails, the changes will not take effect.
                  </span>
                </ReactTooltip>
              </div>
            </div>
            <div className="col-md-4">
              <input type="email" className="form-control" id="emailId" style={{fontSize:'inherit'}} defaultValue={user.emailId} required />
            </div>
          </div>
          <div className="form-group row">
            <label htmlFor="language" className="col-sm-2 col-md-3 col-xl-2 col-form-label">
              Language:
            </label>
            <div className="col-md-4">
              <select id="language" className="form-control" disabled style={{fontSize:'inherit'}}>
                <option defaultValue>{user.language}</option>
              </select>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="permissions" className="col-sm-2 col-md-3 col-xl-2 col-form-label">
              User permission:
            </label>
            <div className="col-md-4">
              <input type="text" className="form-control" id="permissions" style={{fontSize:'inherit'}} defaultValue={'Admin'} disabled />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="permissions" className="col-sm-2 col-md-3 col-xl-2 col-form-label">
              Company name:
            </label>
            <div className="col-md-4">
              <input
                  type="text"
                  className="form-control"
                  defaultValue={user.companyName?user.companyName:""}
                  id="companyName"
                  style={{fontSize:'inherit'}} />
            </div>
          </div>

          <div className="form-group row">
            <label>
              <span className={`col-sm-2 col-md-3 col-xl-2 col-form-label`}>New booking email notifications:</span>
              <Toggle
                  checked={bookingNotificationByEmail}
                  icons={false}
                  id={"bookingNotificationByEmail"}
                  onChange={(e) => setBookingNotificationByEmail(e.target.checked)}
              />
            </label>
          </div>

          <div className="form-group row">
            <label>
              <span className={`col-sm-2 col-md-3 col-xl-2 col-form-label`}>Software updates notifications:</span>
              <Toggle
                  checked={softwareUpdateNotification}
                  icons={false}
                  id={"softwareUpdateNotification"}
                  onChange={(e) => setSoftwareUpdateNotification(e.target.checked)}
              />
            </label>
          </div>

          <div className={styles.usersettingHeader}>Account Settings</div>

          <div className="form-group row">
            <label htmlFor="accountType" className="col-sm-2 col-md-3 col-xl-2 col-form-label">
              Account type:
            </label>
            <div className="col-md-4">
              <input
                  className={"form-control"}
                  id={"accountType"}
                  style={{ fontSize: 'inherit' }}
                  readOnly={true}
                  value={(user.accountType==="trial")?"Free Trial: Plus Plan"
                      :(user.accountType==="plus" && user.planType==="monthly" ?"Plus Plan (monthly)"
                          :(user.accountType==="plus" && user.planType==="yearly" ? "Plus Plan (yearly 20% off)":"Basic Plan (free)"))}
              >
              </input>
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="numberOfRental" className="col-sm-2 col-md-3 col-xl-2 col-form-label">
              Number of rentals:
            </label>
            <div className="col-md-4">
              <input
                  type="number"
                  className="form-control"
                  style={{fontSize:'inherit'}}
                  id="numberOfRentals"
                  value={rentals.length}
                  readOnly={true}
                  min={0}
              />
              <span className={styles.notifyMessage}>{(rentals.length>3 && user && user.linkedlcal)?`Number of rentals: ${rentals.length}. You have linked channels.
                    Upgrade to the Plus Plan to continue using Beehaz successfully`: ((rentals.length>3 || (user && user.linkedlcal)))?`Number of 
                    rentals: ${rentals.length}. Upgrade to the Plus Plan to continue using Beehaz successfully`:""}</span>
            </div>
          </div>
          
          <br />
          <section className='m-0 p-0 col-md-7 col-lg-6'>
              {`
                Beehaz is currently in Beta and free to use. Soon, we will introduce paid subscriptions to unlock even more value.
                You’ll receive a notification as soon as they become available!
              `}
          </section>
          {/* <div className="form-group row m-0 ">
            <label htmlFor="twoYearBookingWindow" className="col-sm-2 col-xs-2 col-md-3 col-xl-2 col-form-label pl-0">
              Upgrade to plus plan:
            </label>
          </div>
          <div className="form-group row m-0">
            <div className="col-sm-6">
              <div className={styles.form_check}>
                <input
                    type="radio"
                    className={styles.two_yrs_input}
                    id="pricePerMonth"
                    name={'priceSelect'}
                    value={radioSelection}
                    onChange={(e)=>setRadioSelection({
                      perYear:!e.target.checked,
                      perMonth:e.target.checked,
                    })}
                />
                <label className={`${styles.two_yrs_label} pl-1`}>{perMonthcost} USD per month</label>
              </div>
            </div>
          </div>
          <div className="form-group row pl-3">
            <div className="col-sm-6">
              <div className={styles.form_check}>
                <input
                    type="radio"
                    className={styles.two_yrs_input}
                    id="pricePerYear"
                    name={'priceSelect'}
                    value={radioSelection}
                    onChange={(e)=>setRadioSelection({
                      perYear:e.target.checked,
                      perMonth:!e.target.checked,
                    })}
                />
                <label  className={`${styles.two_yrs_label} pl-1`}>{perYearcost} USD per year</label>
              </div>
            </div>
          </div>
          <div>
            <button
                className={styles.savBtn}
                onClick={handlePayment}
                disabled={(!radioSelection.perMonth && !radioSelection.perYear)}
            >
              Subscribe to plus plan
            </button>
          </div>
          <div className={`mt-1`}>
            <a className={styles.linkText}>click here to see your invoices</a>
          </div> */}
          <br />
          <br />
          <button className={styles.savBtn} type="submit">
            Save changes
          </button>
          <button className={styles.delBtn} type="button" onClick={handleShow}>
            Delete account
          </button>
        </form>
      </div>
  );
}
export default UserSettings;
