import React,{ useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ReactTooltip from "react-tooltip";
import { withFormik } from 'formik';
import * as yup from 'yup';
import Loading from '../../../../components/loading';

import {
    addChannel,
    editChannel,
    getChannelList,
    deleteChannel
} from '../../../../general_redux/ical_channels/actions';

import Confirmation from '../../../../components/Confirmation';
import AddChannel from './Components/AddChannels/AddChannels';
import ChannelForm from './Components/AddChannels/ChannelForm';

import channelText, { confirmationModal } from './Components/AddChannels/channelText';
import styles from './channel.module.css';

function ChannelManagement(props){
    const dispatch = useDispatch();
    const [addChannelView, setAddChannelView] = useState(false);
    const [channelName, setChannelName] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [confirmDel, setConfirmDel] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const actionForAddChannel = useSelector(({ icalChannels }) => icalChannels && icalChannels.actionForAddChannel);
    const actionForEditChannel = useSelector(({ icalChannels }) => icalChannels && icalChannels.actionForEditChannel);
    const actionForDeleteChannel = useSelector(({ icalChannels }) => icalChannels && icalChannels.actionForDeleteChannel);
    const actionForGetChannels = useSelector(({ icalChannels }) => icalChannels && icalChannels.actionForGetChannels);
    const channels = useSelector(({icalChannels}) => icalChannels && icalChannels.channels);
    const rentals = useSelector(({rentals}) => rentals && rentals.rentals);
    
    useEffect(() => {
        dispatch(getChannelList());
    }, []);

    const handleSubmit = (data) => {
        const links = [];

        rentals.forEach(rental => {
            const icalLink = data[`icalImportLink-${rental.id}`] || '';

            if (editMode) {
                links.push({
                    id: initialValues.icalLinks.find(linkObject => linkObject.rentalId === rental.id)?.id,
                    rentalId: rental.id,
                    icalImportLink: icalLink
                });
            } else {
                links.push({ 
                    rentalId: rental.id,
                    icalImportLink: icalLink
                });
            }
            
        });

        const payload = {
            channel: {
                ota: channelName ? channelName : '',
                name: data.name,
                color: data.color,
                paymentStatus: data.paymentStatus,
            },
            icalLinks: links
        };

        if (editMode) {
            payload['channel']['id'] = initialValues.channel.id
            dispatch(editChannel(payload));
        } else {
            dispatch(addChannel(payload));
        };

    };

    const FormikEnhancer = withFormik({
        validationSchema: yup.object({
            name: yup.string().max(50, 'Must be less than 50 characters'),
            color: yup.string(),
            paymentStatus: yup.string(),
        }),
        validateOnBlur: false,
        validateOnChange: false,
        mapPropsToValues: () => {
            const initValue = {
                id: initialValues ? initialValues.channel.id : undefined,
                name: initialValues ? initialValues.channel.name : '',
                color: initialValues ? initialValues.channel.color : '#3D3D3D',
                paymentStatus: initialValues ? initialValues.channel.paymentStatus : 'Paid',
            };
            if (initialValues?.icalLinks) {
                initialValues.icalLinks.forEach(link => (
                    initValue[`icalImportLink-${link.rentalId}`] = link.icalImportLink
                ));
            };
            return initValue;
        },
        handleSubmit: (values, { setSubmitting }) => {
            handleSubmit(values);
            setSubmitting(false);

            setShowForm(false);
        },
        displayName: 'ChannelForm',
    })(ChannelForm);


    const getChannelName = (channel) => {
        if (channel.ota === 'Other')
            return channel.name
        if (channel.ota === 'VRBO')
            return 'VRBO (HomeAway)'
        else
            return channel.ota
    };

    const onSelection = (data) => {
        setAddChannelView(false);
        setChannelName(data.value);
        setInitialValues(null);
        setEditMode(false);
        setShowForm(true);
    };

    const handleEdit = (data) => {
        setInitialValues(data);
        setChannelName(data.channel.ota);
        setEditMode(true);
        setShowForm(true);
    };

    const handleDelete = (id) => {
        setConfirmDel(false);
        dispatch(deleteChannel(id));
    };

    return(
        <>
            <Loading
                loadingStatus = { actionForAddChannel.loading || actionForEditChannel.loading || actionForGetChannels.loading || actionForDeleteChannel.loading }
            />
            <div className={styles.guestHeader}>
                <div>
                    <div className={styles.header}>
                        <span className='ml-2 mt-2'>This feature will be ready soon.</span>
                        {/* <span>iCal Connections</span>
                        <div className={styles.toolTipIconContainer}>
                            <InfoOutlinedIcon
                                fontSize="small"
                                color="white"
                                data-tip
                                data-for="newChannel"
                                className={styles.toolTipIcon}
                            />
                            <ReactTooltip place="bottom" type="dark" id="newChannel" className={styles.toolTip}>
                          <span className={styles.lightSp}>
                            Beehaz uses iCal connections to synchronize rental calendars with other
                            channels. This allows you to view all reservations from one place.
                            Synchronization times may vary according to the connected calendars.
                            Reservation details shared by other channels will vary.
                          </span>
                          <br></br>
                          <br></br>
                          <span className={styles.lightSp}>
                            Note that your rates, minimum stay requirements, and calendar window will not synchronize. We recommend disabling instant
                            bookings on OTAs.
                          </span>
                            </ReactTooltip>
                        </div> */}
                    </div>
                </div>
                {/* {!showForm && (
                    <div className={styles.flexEnd}>
                        <button className={`${styles.btn} w-25`} onClick={()=>setAddChannelView(true)}>
                            Add Connection
                        </button>
                    </div>
                )} */}
            </div>
            <div className={`ml-4 mt-2 ${styles.channelHeader}`}>
                Channels is a feature that will enable you to connect Beehaz to external calendars.
            </div>
            {/* <AddChannel 
                show={addChannelView}
                onHide={()=>setAddChannelView(false)}
                onSelection={(data)=>onSelection(data)}
            />
            { !showForm ? (
                <div className={`p-3`} style={{"marginTop":"30px"}}>
                    <div className={styles.channelHeader}>
                        Connected Channels
                    </div>
                    <Confirmation
                        onHide={() => {
                            setConfirmDel(false);
                        }}
                        show={!!confirmDel}
                        confirmHeader={confirmationModal.Header}
                        confirmBody={confirmationModal.Body}
                        onConfirmAct={ () => {
                            handleDelete(initialValues.channel.id);
                        }}
                    />
                    {(channels && channels.length === 0) ? (
                        <>
                            <span>You have not yet linked any calendar to Beehaz.</span>
                        </>
                    ):(
                        <>
                            {channels && channels.map(channelObject => (
                                <>
                                    <div className={styles.listItem} key={channelObject.channel.id}>
                                        <div>{getChannelName(channelObject.channel)}</div>
                                        <div className={`{styles.modalFooter} p-0 d-flex justify-content-center `}>
                                            <button
                                                type="submit"
                                                className={styles.btnClass}
                                                style={{backgroundColor:"#439a86"}}
                                                onClick={()=> {
                                                    handleEdit(channelObject);
                                                }}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                className={styles.btnClass}
                                                style={{backgroundColor:"#dc3545"}}
                                                onClick={ (e) => {
                                                    e.preventDefault();
                                                    setInitialValues(channelObject);
                                                    setConfirmDel(true);
                                                }}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                    <hr className={styles.underLine} />
                                </>
                            ))}
                        </>
                    )}
                </div>
            ):(
                <div className={`p-3`}>
                    <div className={` d-flex justify-content-between`}>
                        <div className={styles.editHeader}>
                            <div>{channelName === 'Other' ? `Add/edit connection: ${channelName}` : channelName}</div>
                        </div>
                        <button
                            className={styles.btnSave}
                            onClick={()=> {
                                setShowForm(false);
                            }}
                        >Back</button>
                    </div>
                    <hr className={styles.underLine} />
                    <div>
                        <p>{channelText[`${channelName}`]}</p>
                    </div>
                    <div>
                        <FormikEnhancer/>
                    </div>
                </div>
            )} */}
        </>
    )
}

export default ChannelManagement;
