import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux'
import {Form, InputGroup} from 'react-bootstrap';
import getSymbolFromCurrency from 'currency-symbol-map';
import {Formik} from 'formik';
import {toast} from "react-toastify";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ReactTooltip from "react-tooltip";
import MultiSelect from '../../../BulkDefaultChanges/MultiSelect';
import AddEditFee from '../../components/AddEditFee/AddEditFee';
import AddEditTax from '../../components/AddEditTax/AddEditTax';
import ConfirmDelete from '../../components/ConfirmDelete/ConfirmDelete';
import { calculateFeesAndTaxes } from '../../../../../../../../common/functions/utils';
import styles from '../../../../rates.module.css';


const WeeklyRateForm = (props) => {

    const {
        initialValues,
        validationSchema,
        handleSubmit,
        onHide,
        editMode,
        feeData,
        taxData,
        currentRentalId,
    } = props;

    const rentals = useSelector(({rentals}) => rentals && rentals.rentals);
    const currency = useSelector(({ user }) => user && user.user && user.user.currency);

    const [selectedRentals, setSelectedRentals] = useState([]);
    const [editFeeValues, setEditFeeValues] = useState(null);
    const [editTaxValues, setEditTaxValues] = useState(null);
    const [showWeeklyFee, setShowWeeklyFee] = useState(false);
    const [showWeeklyTax, setShowWeeklyTax] = useState(false);
    const [rate, setRate] = useState(initialValues.rate);
    const [feeList, setFeeList] = useState(feeData);
    const [taxList, setTaxList] = useState(taxData);
    const [total, setTotal] = useState(0);
    const [feeIndex, setFeeIndex] = useState(null);
    const [taxIndex, setTaxIndex] = useState(null);
    const [feeAnchorEl, setFeeAnchorEl] = useState(null);
    const [taxAnchorEl, setTaxAnchorEl] = useState(null);

    useEffect(() => {

        const feesAndTaxesList = [...feeList, ...taxList];

        setTotal(rate + calculateFeesAndTaxes(feesAndTaxesList, rate, 7));

        return () => {};

    }, [rate, feeList, taxList]);

    const findRentalById = (id) => {
        return rentals.find((rental) => Number(rental.id) === Number(id));
    };

    const selectedCurr = findRentalById(currentRentalId)?.currency || currency;

    const onEdit = (data, setData, target, openForm) => {
        setData({...data, index: target});
        openForm(true);
    };
    
    const onDelete = (array, setArray, index) => {
        const updatedArray = array.filter((tax, i) => i !== index && tax);
        setArray(updatedArray);
        return updatedArray;
    };

    const getOptions = () => {
        const tmp = rentals.map((row) => ({value: row.id, label: row.name}));
        return tmp;
    };

    const hasTheSameCurrency = (selectedRentals) => {
        const selectedRentalsObjects = selectedRentals ? rentals.filter(rental => selectedRentals.includes(rental.id)) : [];
        
        if (selectedRentalsObjects.length > 0) {
            const rentalOne = selectedRentalsObjects[0];
            const sameCurrency = selectedRentalsObjects.every(rental => rental.currency === rentalOne.currency);
            if (sameCurrency) {
                return rentalOne.currency;
            };
        };

        return 0;
    };

    const diffCurrencyWarning = (selectedRentals) => {
        if (!hasTheSameCurrency(selectedRentals)) {
            toast.warn('Different currencies used in different rentals. Check the changes.', {style: {color:'#484848'}});
        };
    };

    const dataOverwriteWarning = (selectedRentals) => {
        if (selectedRentals.length > 1) {
            toast.warn('Important: if you have fixed-term rules already set to any of the selected rentals, these will be overwritten when you save your changes.', {style: {color:'#484848'}});
        };
    };

    const resetForm = (handleReset) => {
        handleReset();
        setSelectedRentals([]);
        onHide();
    };
    
    const toggleShowWeeklyFee = () => {
        setShowWeeklyFee(!showWeeklyFee);
    };

    const toggleShowWeeklyTax = () => {
        setShowWeeklyTax(!showWeeklyTax);
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
                validateOnChange={false}
                validateOnBlur={false}
            >
                {({ values, errors, setFieldValue, handleChange, handleReset, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                        <Form.Row className='py-1'>
                        { editMode ?
                                <Form.Label className={`col-sm-6 col-lg-4 col-form-label`}>Rental name: {findRentalById(currentRentalId)?.name || ''}</Form.Label>
                            :
                                <>
                                <Form.Label className={`col-sm-6 col-lg-4 col-form-label`}>Select rental:*</Form.Label>
                                <div className="col-sm-5 col-lg-4">
                                    <MultiSelect
                                        options={getOptions()}
                                        value={selectedRentals}
                                        name="rentalIds"
                                        onChange={(selectedOptions) => {
                                            const selectedIds = selectedOptions.map(option => option.value);
                                            setFieldValue('rentalIds', selectedIds);
                                            setSelectedRentals(selectedOptions);
                                        }}
                                        onBlur={() => {
                                            diffCurrencyWarning(values.rentalIds);
                                            dataOverwriteWarning(values.rentalIds);
                                        }}
                                        fieldHasError={errors.rentalIds}
                                    />
                                    <div style={{fontSize: '0.8em', color: '#dc3545', padding: '3px 0em 0em'}}>{errors.rentalIds}</div>
                                </div>
                                </>
                            }
                        </Form.Row>
                        <br/>
                        <Form.Row>
                            <Form.Label className="col-sm-6 col-lg-4 col-form-label">Set weekly rate (fixed price for 7 nights):</Form.Label>
                            <div className="col-sm-5 col-lg-4">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name="rate"
                                        onChange={(e) => {
                                            handleChange(e);
                                            setRate(Number(e.target.value));
                                        }}
                                        isInvalid={!!errors.rate}
                                        defaultValue={values.rate}
                                        className={styles.form_entry}
                                        min={0}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2" className={styles.form_entry}>{getSymbolFromCurrency(selectedCurr)}</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">
                                         {errors.rate}
                                    </Form.Control.Feedback>                                
                                </InputGroup>
                            </div>
                        </Form.Row>
                        <Form.Row>
                            <Form.Label className="col-sm-6 col-lg-4 col-form-label">
                                Extra night for weekly rate:
                                <InfoOutlinedIcon
                                    fontSize="small"
                                    color="inherit"
                                    data-tip
                                    data-for="weekly-booking"
                                    className={`mb-3 ml-1 ${styles.toolTipIcon}`}
                                />
                                <ReactTooltip place="bottom" type="dark" id="weekly-booking" effect="solid" className={styles.toolTip}>
                                <span>
                                    Fixed-term weekly reservations set a fixed rate for 7-nights bookings. If you decide to change this 
                                    reservation later by adding or subtracting one night, this special extra night rate will be used to
                                    calculate the new price. Reservations created with the ‘add fixed’ button cannot be transformed 
                                    into regular reservations after being generated.
                                </span>
                            </ReactTooltip>
                            </Form.Label>
                            <div className="col-sm-5 col-lg-4">
                                <InputGroup className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name="extraRateNight"
                                        onChange={handleChange}
                                        isInvalid={!!errors.extraRateNight}
                                        defaultValue={values.extraRateNight}
                                        className={styles.form_entry}
                                        min={0}
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text id="basic-addon2" className={styles.form_entry}>{getSymbolFromCurrency(selectedCurr)}</InputGroup.Text>
                                    </InputGroup.Append>
                                    <Form.Control.Feedback type="invalid">
                                         {errors.extraRateNight}
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </div>
                        </Form.Row>
                        <br/>
                        <div>
                            {(feeList.length && !showWeeklyFee && !showWeeklyTax) ?
                                <>
                                    <hr />
                                    <label className={styles.tableTitle}>Weekly fees:</label>
                                    <table className={styles.tableContainer}>
                                    {feeList.map((fee, index) => (
                                        <tr className={`m-0 p-0 col-sm-11 col-lg-8 ${styles.tableContent}`} key={index}>
                                            <td>{`${fee?.name}`}</td>
                                            <td>{`${fee?.amount} ${fee.feeType === 'percentage' ? '%' : getSymbolFromCurrency(selectedCurr)} ${fee?.modality}`}</td>
                                            <td className={styles.tableItem}>
                                                <span className={styles.tableIcon}>
                                                    <i
                                                        className="fa fa-pencil-square-o"
                                                        onClick={() => onEdit(fee, setEditFeeValues, index, setShowWeeklyFee)}
                                                        style={{ fontSize: "18px", marginTop: "2.3px" }}
                                                    />
                                                </span>
                                                <span className={styles.tableIcon}>
                                                    <i
                                                        className="fa fa-trash"
                                                        onClick={(e) => {
                                                            setFeeIndex(index);
                                                            setFeeAnchorEl(feeAnchorEl ? null : e.currentTarget);
                                                        }}
                                                        aria-describedby="popover-weekly-fee"
                                                        style={{fontSize: "18px"}}
                                                    />
                                                    <ConfirmDelete
                                                        popoverId={"popover-weekly-fee"}
                                                        open={Boolean(feeAnchorEl)}
                                                        anchorEl={feeAnchorEl}
                                                        onClose={() => setFeeAnchorEl(null)}
                                                        onConfirmAct={() => {
                                                            const updatedList = onDelete(feeList, setFeeList, feeIndex);
                                                            setFieldValue('fees', updatedList);
                                                            setFeeAnchorEl(null);
                                                        }}
                                                        title={'Are you sure you want to delete this fee?'}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </table>
                                </>
                                :
                                <></>
                            }
                            {(feeList.length >= 5 && !showWeeklyFee) ? <></> : <hr />}
                            {showWeeklyFee ?
                                <label className={`mb-4 ${styles.tableTitle}`}>Add weekly fee:</label>
                            :
                                ((feeList.length >= 5) ? <></> : <label onClick={toggleShowWeeklyFee} className={styles.tableBtnTitle}><AddCircleOutlineIcon style={{color: '#555555'}} /> Add weekly fee</label>)
                            }
                            {showWeeklyFee && (
                                <AddEditFee
                                    show={(e) => {
                                        setShowWeeklyFee(e);
                                        if (!e) {
                                            setEditFeeValues(null);
                                        };
                                    }}
                                    feeList={feeList}
                                    setFeeList={(e) => {
                                        setFeeList(e);
                                        setFieldValue('fees', e);
                                    }}
                                    editValues={editFeeValues}
                                    currency={selectedCurr}
                                />
                            )}
                        </div> 
                        <div>
                        {(taxList.length && !showWeeklyTax && !showWeeklyFee) ?
                                <>
                                    <hr />
                                    <label className={styles.tableTitle}>Weekly taxes:</label>
                                    <table className={styles.tableContainer}>
                                    {taxList.map((tax, index) => (
                                        <tr className={`m-0 p-0 col-sm-11 col-lg-8 ${styles.tableContent}`} key={index}>
                                            <td>{`${tax?.name}`}</td>
                                            <td>{`${tax?.amount} ${tax.taxType === 'percentage' ? '%' : getSymbolFromCurrency(selectedCurr)} ${tax?.modality}`}</td>
                                            <td className={styles.tableItem}>
                                                <span className={styles.tableIcon}>
                                                    <i
                                                        className="fa fa-pencil-square-o"
                                                        onClick={() => onEdit(tax, setEditTaxValues, index, setShowWeeklyTax)}
                                                        style={{ fontSize: "18px", marginTop: "2.3px" }}
                                                    />
                                                </span>
                                                <span className={styles.tableIcon}>
                                                    <i
                                                        className="fa fa-trash"
                                                        onClick={(e) => {
                                                            setTaxIndex(index);
                                                            setTaxAnchorEl(taxAnchorEl ? null : e.currentTarget);
                                                        }}
                                                        aria-describedby="popover-weekly-tax"
                                                        style={{fontSize: "18px"}}
                                                    />
                                                    <ConfirmDelete
                                                        popoverId={"popover-weekly-tax"}
                                                        open={Boolean(taxAnchorEl)}
                                                        anchorEl={taxAnchorEl}
                                                        onClose={() => setTaxAnchorEl(null)}
                                                        onConfirmAct={() => {
                                                            const updatedList = onDelete(taxList, setTaxList, taxIndex);
                                                            setFieldValue('taxes', updatedList);
                                                            setTaxAnchorEl(null);
                                                        }}
                                                        title={'Are you sure you want to delete this tax?'}
                                                    />
                                                </span>
                                            </td>
                                        </tr>
                                    ))}
                                    </table>
                                </>
                                :
                                <></>
                            }
                            {(taxList.length >= 5 && !showWeeklyTax) ? <></> : <hr />}
                            {showWeeklyTax ?
                                <label className={`mb-4 ${styles.tableTitle}`}>Add weekly tax:</label>
                            :
                                ((taxList.length >= 5) ? <></> : <label onClick={toggleShowWeeklyTax} className={styles.tableBtnTitle}><AddCircleOutlineIcon style={{color: '#555555'}} /> Add weekly tax</label>)
                            }
                            {showWeeklyTax && (
                                <AddEditTax
                                    show={(e) => {
                                        setShowWeeklyTax(e);
                                        if (!e) {
                                            setEditTaxValues(null);
                                        };
                                    }}
                                    taxList={taxList}
                                    setTaxList={(e) => {
                                        setTaxList(e);
                                        setFieldValue('taxes', e);
                                    }}
                                    editValues={editTaxValues}
                                    currency={selectedCurr}
                                />
                            )}
                        </div>
                        <hr />                
                        <br/>
                        <br/>
                            <div className='p-2 d-flex justify-content-center flex-column'>
                                <div className={`col d-flex justify-content-around ${styles.endNote}`}> 
                                    {`Weekly rate total: ${parseFloat((total || 0).toFixed(2))} ${getSymbolFromCurrency(selectedCurr)}`}
                                </div>
                                <label className='col d-flex justify-content-center'>(For 1 guest)</label>
                                <div className='mt-2 col d-flex justify-content-center'>
                                    <button type="submit" className={`col-lg-2 p-1 mx-2 col-sm-5 ${styles.allBtn}`}>Save</button>
                                    <button type="reset" className={`col-lg-2 p-1 mx-2 col-sm-5 ${styles.allBtn}`} style={{backgroundColor:"#6c757d"}} onClick={() => resetForm(handleReset)}>Discard</button>
                                </div>
                            </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default WeeklyRateForm;
