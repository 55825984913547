import React, { useState, useEffect, useRef } from "react";
import Loading from "../../../../components/loading";
import { useSelector, useDispatch } from "react-redux";
import { Col, Container, Row, InputGroup } from "react-bootstrap";
import * as XLSX from "xlsx";
import {
  Input,
  InputLabel,
  Select,
  Checkbox,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import { useResizeDetector } from "react-resize-detector";
import { createTheme, makeStyles, MuiThemeProvider } from "@material-ui/core/styles";
import moment from 'moment';
import Table from "../../../../components/Table";
import SearchableSelect from '../../../../components/SearchableSelect/SearchableSelect';
import InvoiceSend from "../../../../components/InvoiceSend";
import ConfirmMessage from "../../../../components/Confirmation";
import EditInvoice from "./Components/EditInvoice";
import {
  editInvoiceList,
  deleteMultipleInvoice,
  getFilterInvoices
} from "../../../../general_redux/invoice/actions";
import { customClassification } from "../../../../common/functions/utils";
import styles from "./invoices.module.css";

const myTheme = createTheme({
  palette: {
    primary: {
      // works
      main: "#439a86",
      contrastText: "#fff",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    position: "unset",
    whiteSpace: "nowrap",
    margin: "0 12px 0 0",
    ["@media (max-width:991px)"]: {
      // eslint-disable-line no-useless-computed-key
      margin: "0 0 6px 0",
    },
    color: "white",
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
  },
  input: {
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
  },
  MuiSelect: {
    width: "170px",
    ["@media (max-width:991px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "100%",
    },
    ["@media (max-width:576px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "100%",
    },
    background: "#fff",
    padding: "0 8px",
    border: "none",
    borderRadius: "5px",
    fontSize: "0.8rem",
    height: "32px",
  },
  textField: {
    // width: 200,
    color: "white",
    width: "170px",
    ["@media (max-width:991px)"]: {
      // eslint-disable-line no-useless-computed-key
      width: "95%",
    },
    background: "#fff",
    padding: "0 8px",
    border: "none",
    borderRadius: "5px",
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
    height: "32px",
  },
}));

function Invoices() {
  const { ref } = useResizeDetector();

  const actionForEditInvoice = useSelector(({ invoice }) => invoice && invoice.actionForEditInvoice);
  const actionForDeleteInvoice = useSelector(({ invoice }) => invoice && invoice.actionForDeleteInvoice);
  const actionForGetInvoice = useSelector(({ invoice }) => invoice && invoice.actionForGetInvoice);

  const [selectedRentals, setSelectedRentals] = useState([]);
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);

  const dropWrapper = useRef(null);
  const dropMobileWrapper = useRef(null);
  const user = useSelector(({ user }) => user && user.user);

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [dropActive, setActive] = useState(false);
  const [dropMobileActive, setMobileActive] = useState(false);
  const [selectbar,setSelection]=useState(false);

  const [confirmDel, setConfirmDel] = useState(false);
  const [deleteInvoice, setDeleteInvoice] = useState([]);

  const paymentStatus = [
    { value: 1, label: 'Not Paid' },
    { value: 2, label: 'Partially Paid' },
    { value: 3, label: 'Paid' },
    { value: 4, label: 'Cancelled' },
  ];

  const mainWrapper = useRef(null);
  const [showHeader, setShowHeader] = useState(false);

  const classes = useStyles();
  const dispatch = useDispatch();
  const [viewSendInvoice, setViewSendInvoice] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const invoiceList = useSelector(
    ({ invoice }) => invoice && invoice.invoiceList
  );
  const [selectedData, setSelectedData] = useState(null);
  const dateFormat = useSelector(
    ({ generalSetting }) => generalSetting && generalSetting.setting.dateDisplay
  );

  const rentals = useSelector(
    ({ rentals }) =>
      rentals &&
      rentals.rentals &&
      rentals.rentals.map((row) => ({ label: row.name, value: row.id }))
  );
  
  const guests = useSelector(
    ({ guests }) =>
      guests &&
      guests.guestList &&
      guests.guestList.map((row) => ({ label: row.name, value: row.id }))
  );

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const queryResult = useSelector((state) => state.invoice.invoiceList);

  function clearFilter() {
    setSelectedRentals([]);
    setSelectedGuests([]);
    setSelectedPaymentStatus([]);
  }

  useEffect(() => {
    function handler(event) {
      if(!mainWrapper.current?.contains(event.target)){
        setSelection(false);
      }
      if(!dropWrapper.current?.contains(event.target)){
        setActive(false);
      }
      if(!dropMobileWrapper.current?.contains(event.target)){
        setMobileActive(false);
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, []);

  const getPlaceholder = (items, selectedItems) => {
    let out = [];
    for (let i = 0; i < selectedItems.length; i++) {
      const item = items.find(item => item.value === selectedItems[i])["label"];
      out.push(item);
    }
    return out.length > 0 ? out.join(", ") : "";
  };

  useEffect(() => {
    const payload = {
      rentalIds: selectedRentals.map(value => value),
      paymentStatus: paymentStatus.filter(item => selectedPaymentStatus.includes(item.value)).map(value => value.label),
      guestIds: selectedGuests.map(value => value),
    };
    // console.log(payload);
    dispatch(getFilterInvoices(payload));
  }, [selectedRentals, selectedPaymentStatus, selectedGuests]);

  const handleDiscardChanges = () => {
    setViewInvoice(false);
    setSelectedData(null);
  }

  useEffect(() => {
    if (actionForEditInvoice.success) {
      handleDiscardChanges();
    }
  }, [actionForEditInvoice.success]);

  const handleEdit = (data) => {
    setSelectedData(data);
    setViewInvoice(true);
  };

  const handleSendInvoice = (data) => {
    setSelectedData(data);
    setViewSendInvoice(true);
  }

  const handleSendInvoiceEdit = (data) => {
    setViewSendInvoice(false);
    handleEdit(data);
  }

  const confirmDelete = (selected) => {
    const invoiceList = queryResult
      .filter((inq) => selected.includes(inq.id)).map((b) => b.id);
    const payload = {
      invoice: invoiceList,
    };
    dispatch(deleteMultipleInvoice(payload));
    setDeleteInvoice([]);
  };

  const handleDelete = (selected) => {
    if(selected.length){
      setDeleteInvoice(selected);
      setConfirmDel(true);
    } else {
      confirmDelete(selected);
    }
  };

  const cols = [
    { lable: 'Invoice Nr', key: 'invoiceNumber' },
    { lable: 'Created', key: 'createdOn', isDate: true },
    { lable: 'Invoice Due', key: 'dueDate', isDate: true },
    { lable: 'Total', key: 'total' },
    { lable: 'Invoice Status', key: 'paymentStatus' },
    { lable: 'Guest', key: 'guestName' },
    { lable: 'Rental', key: 'rentalName' },
    { lable: 'Mark as Sent', key: 'markSent' },
    { lable: 'Actions', key: '' },
  ];

  const handleDownload = (invoiceD) => {
    window.open(invoiceD.downloadLink,"_parent")
  };

  const invoiceCol = [
    { label: "Invoice nr.", key: "invoiceNumber" },
    { label: "Name", key: "guestName" },
    { label: "Rental", key: "rentalName" },
    { label: "Rent", key: "invoiceItem" },
    { label: "Fees", key: "feeItems" },
    { label: "Taxes", key: "taxItems" },
    { label: "Total", key: "total" },
    { label: "Status", key: "paymentStatus" },
  ];

  const handleDownloadHeader = (e, sheetName) => {
    e.preventDefault();
    let excelData = [];
    queryResult.forEach((items) => {
      let tmp = {};
      invoiceCol.map((col) => {
        if(col.label === "Rent" || col.label === "Fees") {
          tmp[col.label] = items[col.key] && items[col.key].length ? items[col.key].reduce((total, obj) => obj.fee + total,0) : 0
        } else if(col.label === "Taxes") {
          tmp[col.label] = items[col.key] && items[col.key].length ? items[col.key].reduce((total, obj) => obj.tax + total,0) : 0
        } else {
          tmp[col.label] = items[col.key];
        }
      });
      excelData.push(tmp);
    });
    const fileName = `${sheetName}_${Date.now()}`;
    let wb = XLSX.utils.book_new();
    const invoiceHeading = [invoiceCol.map(i => i.label)]
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, invoiceHeading, { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, `${sheetName}Sheet`);
    XLSX.writeFile(wb, `${fileName}.xlsx`, { bookType: "xlsx", type: "array" });
  };

  const MenuProps = {
    paperStyles: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 180,
        marginTop: 50,
        marginLeft: -18,
        top: 54,
      },
    },
  };


  const handleSubmit = (data, totalCost, tableData,tableData2, tableData3) => {
    const payload = {
      id: selectedData.id,
      guestId: selectedData.guestId,
      bookingId: selectedData.bookingId,
      dueDate: moment(data.dueDate).utc(false).format('YYYY-MM-DD'),
      dueDateUtc: moment(data.dueDate).utc(true).format('YYYY-MM-DD HH:mm:ss'),
      invoiceDate: moment(data.issueDate).utc(false).format('YYYY-MM-DD'),
      invoiceDateUtc: moment(data.issueDate).utc(true).format('YYYY-MM-DD HH:mm:ss'),
      invoiceNumber: Number(data.invoiceNr || selectedData.invoiceNumber),
      invoiceItem: tableData.map((item) => ({title: item.title, fee: Number(item.fee)})),
      feeItems: tableData2.map((item) => ({title: item.title, fee: Number(item.fee)})),
      taxItems: tableData3.map((item) => ({title: item.title, tax: Number(item.tax)})),
      currency: selectedData.currency,
      total: Number(totalCost),
      markSent: selectedData.markSent || false,
      downloadLink: selectedData?.downloadLink || "",
      recipientDetail: {
        name: data.r_name,
        company: data.r_comp,
        address1: data.r_address1,
        address2: data.r_address2,
        address3: data.r_address3,
        email: selectedData.recipientDetail.email,
      },
      senderDetail: {
        name: data.s_name,
        address1: data.s_address1,
        address2: data.s_address2,
        address3: data.s_address3,
        country: data.s_country
      },
      invoiceText: data.invoiceText,
      invoiceFooter: data.invoiceFooter,
      paymentStatus: data.status,
      bookingNumber: Number(data.bookingNumber) || 0,
      showBookingNumber: data.showBookingNumber,
      stripeButton: data.stripeButton,
      markBookingAsPaid: data.markBookingAsPaid,
      whenPaidMarkBookingAsPaid: data.whenPaidMarkBookingAsPaid,
    };
    // console.log(payload);
    dispatch(editInvoiceList(payload));
  };


  return (
    <>
      <MuiThemeProvider theme={myTheme}>
        <Loading loadingStatus={actionForGetInvoice.loading || actionForEditInvoice.loading || actionForDeleteInvoice.loading} />
        <div className={`p-2 ${styles.header}`} ref={ref}>
          <div className={styles.first_col}>
            
            <div className={`${styles.btn_group_1}`}>
              <div 
                ref={mainWrapper}
                onClick={(e) => setShowHeader(!showHeader)}
                className={`${styles.filter_filter_name}`}
              >
                <div className={styles.btnWrapper}>
                  <div className={`${styles.filterName} ${styles.downloadFilter}`}>
                    <i className={`fa fa-filter ${styles.filter_icon}`} />{" "}
                    <span className={styles.filter_title_header}>Filter</span>
                  </div>
                  <div className={styles.filterDrop}>
                    <i className={`fa fa-caret-down`}/>
                  </div>
                </div>
              </div>
              { showHeader &&
                <button className={`${styles.btn_clear_filter}`} onClick={clearFilter}>
                  Clear Filter
                </button>
              }
            </div>

            <div
              className={`${styles.dropdown}`}
              onClick={() => setMobileActive(!dropMobileActive)}
              ref={dropMobileWrapper}
            >
              <div className={`${styles.filter_filter_name}`}>
                <div className={styles.btnWrapper}>
                  <div className={`${styles.filterName} ${styles.downloadFilter}`}>
                    <span className={styles.filter_title_header}>Download</span>
                  </div>
                  <div className={styles.filterDrop} onClick={()=>setSelection(!selectbar)}>
                    <i className={`fa fa-caret-down`}/>
                  </div>
                </div>
              </div>
              <div className={dropMobileActive?`${styles.dropdownContentActive}`:`${styles.dropdownContent}`}>
                <a href="" onClick={(e) => handleDownloadHeader(e, "Invoice")}>Excel invoice list</a>
              </div>
            </div>
            
          </div>
          
          { showHeader &&
            <div className={styles.secound_col}>
              <InputGroup className={styles.input_group}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  className={classes.inputLabel}
                >
                  Rental
                </InputLabel>
                <SearchableSelect
                  items={rentals}
                  selectedItems={selectedRentals}
                  setSelectedItems={setSelectedRentals}
                  placeholder={getPlaceholder(rentals, selectedRentals)}
                />
              </InputGroup>

              <InputGroup className={styles.input_group}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  className={classes.inputLabel}
                >
                  Status
                </InputLabel>
                <SearchableSelect
                  items={paymentStatus}
                  selectedItems={selectedPaymentStatus}
                  setSelectedItems={setSelectedPaymentStatus}
                  placeholder={getPlaceholder(paymentStatus, selectedPaymentStatus)}
                />
              </InputGroup>

              <InputGroup className={styles.input_group}>
                <InputLabel
                  id="demo-multiple-checkbox-label"
                  className={classes.inputLabel}
                >
                  Guest
                </InputLabel>
                <SearchableSelect
                  items={guests}
                  selectedItems={selectedGuests}
                  setSelectedItems={setSelectedGuests}
                  placeholder={getPlaceholder(guests, selectedGuests)}
                />
              </InputGroup>
            </div>
          }
        </div>
        <EditInvoice
          show={selectedData && viewInvoice}
          handleReset={handleDiscardChanges}
          selectedInvoice={selectedData}
          handleSubmit={handleSubmit}
          bookingId={selectedData ? selectedData.bookingId : ""}
          onHide={() => {
            setViewInvoice(false);
            setSelectedData(null);
          }}
        />
        <InvoiceSend
          show = {viewSendInvoice}
          onHide = {() => setViewSendInvoice(false)}
          invoiceData = {selectedData}
          onEdit={(data) => handleSendInvoiceEdit(data)}
        />
        {
          !actionForGetInvoice.loading && (
              <Container fluid>
                <Row className="justify-content-center">
                  <Col xs={12} md={12} className={`pt-3`}>
                    <Table
                        rows={customClassification(invoiceList, order, orderBy)}
                        cols={cols}
                        dateFormat={dateFormat.toUpperCase()}
                        onDelete={handleDelete}
                        onEdit={(data) => handleEdit(data)}
                        onDownload={handleDownload}
                        onSendGuest={(data) => handleSendInvoice(data)}
                        order={order}
                        setOrder={setOrder}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                    />
                  </Col>
                </Row>
              </Container>
          )
        }
        <ConfirmMessage
          show={confirmDel}
          onHide={() => {
            setConfirmDel(false);
            setDeleteInvoice([]);
          }}
          confirmHeader={`Warning: This action will delete your invoices`}
          confirmBody={
            <>
              <span>{"Are you sure you want to delete these invoices?"}</span>
            </>
          }
          onConfirmAct={() => {
            confirmDelete(deleteInvoice);
            setConfirmDel(false);
          }}
          confirmDelete={true}
        />
      </MuiThemeProvider>
    </>
  );
}

export default Invoices;
