import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Box, Popover} from "@material-ui/core";
import AddTax from './AddTax/AddTax';
import Loading from '../../../../../../components/loading';
import { customSort, customClassification } from "../../../../../../common/functions/utils";
import { addTax, deleteTax, editTax } from '../../../../../../general_redux/rates/actions';
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import Table from '../../../../../../components/Table';
import styles from '../../rates.module.css';

function Taxes() {

  const dispatch = useDispatch();

  const [addTaxModal, setAddTaxModal] = useState(false);
  const [edited, setEdited] = useState(false);

  const [taxCategory,setTaxCategory]=useState([]);
  const actionForFetchTaxes=useSelector(({rates})=>rates && rates.actionForFetchTaxes);
  const actionForAddTax=useSelector(({rates})=>rates && rates.actionForAddTax);
  const actionForEditTax=useSelector(({rates})=>rates && rates.actionForEditTax);
  const actionForDeleteTax=useSelector(({rates})=>rates && rates.actionForDeleteTax);
  const currency =useSelector(({user})=>user && user.user && user.user.currency);
  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const taxesList = useSelector(({ rates }) => rates && rates.taxList);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDelete,setCurrentDelete]=useState(null);

  const cols = [
    { lable: 'Name', key: 'name' },
    { lable: 'Type', key: 'taxType' },
    { lable: 'Amount', key: 'amount' },
    { lable: 'Modality', key: 'modality' },
    { lable: 'Actions', key: '' },
  ];

  useEffect(()=>{
    if(actionForFetchTaxes.success || actionForAddTax.success || actionForEditTax.success || actionForDeleteTax.success){
      const tmp=[];
      if(taxesList){
        rentals.forEach((rental) => {
          const listItems = taxesList.filter((tax) => tax.rentalId === rental.id);
          tmp[`${rental.id}`] = listItems;
        });
        setTaxCategory(tmp);
      }
    }
  },[actionForFetchTaxes,actionForAddTax,actionForEditTax,actionForDeleteTax])

  const closeModal = (data) => {
    if (edited) {
      dispatch(editTax(data));
    } else {
      dispatch(addTax(data));
    }
    setEdited(null);
    setAddTaxModal(false);
  };
  
  const editRow = (val) => {

    const payload = {
      id: val.id,
      rentalId: val.rentalId,
      amount: val.amount,
      taxApplyAdults: val.taxApplyAdults,
      taxApplyChildren: val.taxApplyChildren,
      name: val.name,
      taxType: val.taxType,
      modality: val.modality,
    };
    setEdited(payload);
    setAddTaxModal(true);
  };

  const deleteRow = (id) => {
    dispatch(deleteTax(id));
  };

  const handleDelete=()=>{
    if(currentDelete && currentDelete.id){
      dispatch(deleteTax(currentDelete.id));
    }
    setAnchorEl(null);
    setCurrentDelete(null);
  }

  const handleConfirmDialog = (e,row) => {
    setCurrentDelete(row);
    setAnchorEl( anchorEl ? null : e.currentTarget);
  }

    const invoiceDeleteDialog = () => {
      return (
          <Popover
              id={"popover-1"}
              open={Boolean(anchorEl)}
              onClose={handleConfirmDialog}
              anchorEl={anchorEl}
              anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
              }}
              transformOrigin={{
                  vertical: "center",
                  horizontal: "right",
              }}
              PaperProps={{
                  style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                      borderRadius: 0,
                      display: "flex",
                  },
              }}
          >
              <div className={styles.contentWrap}>
                  <div className={styles.deleteTitle}>
                      Are you sure you want to delete this tax?
                  </div>
                  <div className="text-center m-1">
                      <button
                          onClick={() => handleDelete()}
                          className={styles.delConfirmBtn}
                      >
                          Delete
                      </button>
                      <button
                          onClick={handleConfirmDialog}
                          className={styles.cancelBtn}
                      >
                          Cancel
                      </button>
                  </div>
              </div>
              <Box
                  className={styles.rightArrow}
              />
          </Popover>
      );
    };

  const [tableStates, setTableStates] = useState(
    rentals.length !== 0 && customSort(rentals, 'asc', 'name').reduce((acc, _, index) => {
      acc[index] = { order: 'asc', orderBy: 'name' };
      return acc;
    }, {})
  );

  const handleTableStateChange = (index, newState) => {
    setTableStates((prevState) => ({
      ...prevState,
      [index]: { ...prevState[index], ...newState }
    }));
  };

  const renderTaxesTable = useMemo(() => {
    return (
      <div className="col-lg-10 col-xl-8 px-0 mb-3">
        {rentals.length !== 0 ? customSort(rentals, 'asc', 'name').map((rental, index) => {
          const { order, orderBy } = tableStates[index];

          return (
            <div key={index} className="mb-4">
              <div className='d-flex'>
                {rental.parentRentalId && 
                  <SubdirectoryArrowRightIcon
                      fontSize="small"
                      style={{ color: "#9e9e9e" }}
                  />
                }
                <div className={styles.taxHeader}>{rental.name}</div>
              </div>
              <Table
                rows={customClassification(((taxCategory && taxCategory[rental.id]) ? taxCategory[rental.id] : []), order, orderBy)}
                cols={cols}
                onEdit={editRow}
                onDelete={(data) => deleteRow(data.id)}
                confirmDeleteDialog={invoiceDeleteDialog}
                handleConfirmDialog={handleConfirmDialog}
                order={order}
                orderBy={orderBy}
                setOrder={(newOrder) =>
                  handleTableStateChange(index, { order: newOrder })
                }
                setOrderBy={(newOrderBy) =>
                  handleTableStateChange(index, { orderBy: newOrderBy })
                }
              />
            </div>
          )})
          :
          <h5>No data to display</h5>}
      </div>
    )
  }, [dispatch, tableStates, rentals, taxesList, taxCategory, anchorEl]);

  return (
    <>
      <Loading loadingStatus={actionForFetchTaxes.loading}/>
      <AddTax
        show={addTaxModal}
        onHide={() => {
          setAddTaxModal(false);
          setEdited(null);
        }}
        onEdit={(data) => closeModal(data)}
        viewForEdit={edited}
        currency={currency}
      />
      <div className="py-3">
        <button className={styles.ratelistBtn} onClick={() => setAddTaxModal(true)}>
          Add Tax
        </button>
      </div>
      {renderTaxesTable}
    </>
  );
}

export default Taxes;
