import React, { useState, useEffect,useRef } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { InputGroup,Container,Row,Col } from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { components } from "react-select";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import {createTheme, makeStyles, MuiThemeProvider} from '@material-ui/core/styles';
import {zeroPad} from '../../../../common/functions/utils';
import Table from '../../../../components/Table/TableBackup';
import SearchableSelect from '../../../../components/SearchableSelect/SearchableSelect';
import { customClassification } from "../../../../common/functions/utils";
import {LoadInquiries,DeleteInquiry,downloadHistoricData} from '../../../../general_redux/inquiry/action';
import Loading from '../../../../components/loading';
import CustomDate from '../../../../components/CustomDate';
import ConfirmMessage from '../../../../components/Confirmation';
import styles from './inquiry.module.css';

const myTheme=createTheme({
  palette: {
    primary: { // works
      main: '#439a86',
      contrastText: '#fff',
    },
  },
});

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
  },
  mainContainer: {
    position: "relative",
    zIndex: "auto",
  },
  palette: {
    type: "light",
    primary: {
      light: "#ffffff",
      main: "#26a69a",
      dark: "#004d40",
    },
  },
  formInput: {
    color: "white",
    backgroundColor: "pink",
  },
  inputLabel: {
    whiteSpace: "nowrap",
    color: "white",
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
  },
  textField: {
    color: "white",
    background: "#fff",
    border: "none",
    borderRadius: "5px",
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
    height: "38px",
  },
  input: {
    fontSize: "0.8rem",
    fontFamily: "Montserrat",
  },
  underline: {
    color: "white",
  },
  formLabel: {
    color: theme.palette.primary.light,
  },
  formControl: {
    flexDirection: "row",
  },
  selectMenu: {
    top: "54px",
    width: "180px",
    marginTop: "50px",
    marginLeft: "-18px",
  },
  truncate: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "block !important",
    alignItems: "center",
    margin: "5px 0 0",
    overflow: "hidden",
  },
  cmvlaue:{
    flexWrap:"nowrap !important",
  },
}));

function Inquiry() {

  const classes = useStyles();

  const { width, ref } = useResizeDetector();
  const [showHeader,setShowHeader]=useState(false);
  const wrapperRef = useRef(null);
  const mainWrapper=useRef(null);
  const dropWrapper=useRef(null);
  const dropMobileWrapper = useRef(null);
  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals && rentals.rentals.map((row) => ({label:row.name,value:row.id})));
  const guests=useSelector(({guests})=>guests && guests.guestList && guests.guestList.map((row) => ({label:row.name,value:row.id})));
  const [selectbar,setSelection]=useState(false);
  const user= useSelector(({user})=> user && user.user);
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [dropActive,setActive]=useState(false);
  const [dropMobileActive,setMobileActive] = useState(false);
  const dispatch = useDispatch();
  const inquiryStatus = useSelector(({inquiry})=>inquiry && inquiry.actionForInquiries);
  const inquiryHistoryStatus = useSelector(({inquiry})=>inquiry && inquiry.actionForHistory);
  const historyData = useSelector(({inquiry}) => inquiry && inquiry.historyData);
  const queryResult = useSelector((state) => state.inquiry.inquiryData.map(row => ({...row, booking_nr: `${new Date().getUTCFullYear()}${zeroPad(row.id,4)}`})));
  const dateDisplay = useSelector(({generalSetting})=>generalSetting && generalSetting.setting && generalSetting.setting.dateDisplay);
  
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selectedRentals, setSelectedRentals] = useState([]);
  const [selectedGuests, setSelectedGuests] = useState([]);
  const [selectedBookingStatus, setSelectedBookingStatus] = useState([1]);
  const [selectedPaymentStatus, setSelectedPaymentStatus] = useState([]);
  const [isDownload, setIsDownload] = useState(false);
  const [confirmDel,setConfirmDel]=useState(false);
  const [deleteInquiry, setDeleteInquiry] = useState([]);
  const [clearCheckBoxes, setClearCheckBoxes] = useState(false);

  const paymentStatus = [
    { value: 1, label: "Payment Pending" },
    { value: 2, label: "Partially Paid" },
    { value: 3, label: "Paid" },
  ];

  const bookingStatus = [
    { value: 1, label: "Booked" },
    { value: 2, label: "Cancelled" },
  ];

  const inquiryCol = [
    { label: 'Booking nr.', key: 'id' },
    { label: 'Source', key: 'channel' },
    { label: 'Rental', key: 'rentalName' },
    { label: 'Name', key: 'guestName' },
    { label: 'Arrive', key: 'arrive', format: "DD/MM/YYYY" },
    { label: 'Depart', key: 'depart', format: "DD/MM/YYYY" },
    { label: 'Nights', key: 'nights' },
    { label: 'Adults', key: 'noOfAdults' },
    { label: 'Children', key: 'noOfChildren' },
    { label: 'Net amount', key: 'netAmount' },
    { label: 'Payment status', key: 'paymentStatus' }
  ];

  const guestCol = [
    { label: 'Company', key: 'guestCompany' },
    { label: 'Birth date', key: 'guestDob', format: "DD/MM/YYYY" },
    { label: 'Nationality', key: 'guestNationality' },
    { label: 'Country', key: 'guestCountry' },
    { label: 'Street and nr', key: 'guestStreet' },
    { label: 'Postal code', key: 'postalCode' },
    { label: 'State/Province', key: 'guestState' },
    { label: 'Phone', key: 'guestPhone' },
    { label: 'Email', key: 'guestEmail' }
  ];

  useEffect(() => {
    function handler(event) {
      if(!mainWrapper.current?.contains(event.target)){
        setSelection(false);
      }
      if(!dropWrapper.current?.contains(event.target)){
        setActive(false);
      }
      if(!dropMobileWrapper.current?.contains(event.target)){
        setMobileActive(false);
      }
    }
    window.addEventListener('click', handler)
    return () => window.removeEventListener('click', handler)
  }, []);

   useEffect(() => {
    const payload={
      dateFrom: dateFrom ? moment(dateFrom).utc(true).format('YYYY-MM-DD') : "",
      dateTo: dateTo ? moment(dateTo).utc(true).format('YYYY-MM-DD') : "",
      rentalId: selectedRentals.map(value => value),
      paymentStatus: paymentStatus.filter(item => selectedPaymentStatus.includes(item.value)).map(value => value.label),
      status: bookingStatus.filter(item => selectedBookingStatus.includes(item.value)).map(value => value.label),
      guestsId: selectedGuests.map(value => value),
    }
    dispatch(LoadInquiries(payload));
  }, [selectedRentals,dateFrom,dateTo,selectedPaymentStatus,selectedBookingStatus,selectedGuests]);

  useEffect(() => {
    if(inquiryHistoryStatus.success && isDownload){
      downloadExcel("historic","Reservation_History",historyData);
      setIsDownload(false);
    }
  },[inquiryHistoryStatus,isDownload]);

  function clearFilter() {
    setDateFrom("");
    setDateTo("");
    setSelectedRentals([]);
    setSelectedGuests([]);
    setSelectedBookingStatus([1]);
    setSelectedPaymentStatus([]);
  }
  const cols = [
    { lable: 'Booking Nr', key: 'booking_nr' },
    { lable: 'Channel', key: 'channel' },
    { lable: 'Rental', key: 'rentalName' },
    { lable: 'Guest Name', key: 'guestName' },
    { lable: 'Check-in', key: 'arrive', isDate:true },
    { lable: 'Check-out', key: 'depart',isDate:true },
    { lable: 'Nights', key: 'nights' },
    { lable: 'Net Amount', key: 'netAmount' },
    { lable: 'Created', key: 'createdAt',isDate:true},
    { lable: 'Payment Status', key: 'paymentStatus' },
    { lable: 'Status', key: 'status' },
  ];

  const downloadExcel = (eType, sheetName, rowData) => {
    let excelData = [];
    var inquiryHeading = inquiryCol.map(i => i.label)
    if (eType === "guest" || eType === "historic") {
      inquiryHeading = [...inquiryHeading, ...guestCol.map(i => i.label)]
    }
    rowData.forEach((items) => {
      let tmp = {};
      inquiryCol.map(col => {
          tmp[col.label] = col.format && items[col.key] ? moment(items[col.key]).format(col.format) : col.key === "id" ? `${new Date().getUTCFullYear()}${zeroPad(items[col.key],4)}` : items[col.key];
      });
      if (eType === "guest" || eType === "historic") {
        guestCol.map(col => {
          tmp[col.label] = col.format && items[col.key] ? moment(items[col.key]).format(col.format) : items[col.key];
        });
      }
      excelData.push(tmp);
    });
    const fileName = `${sheetName}_${Date.now()}`;
    let wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, [inquiryHeading], { origin: 'A1' });
    XLSX.utils.book_append_sheet(wb, ws, `${sheetName}Sheet`);
    XLSX.writeFile(wb, `${fileName}.xlsx`, { bookType: "xlsx", type: "array" });
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const selectAllOption = {
    label: "Select all",
    value: "*"
  };
  const MenuProps = {
    paperStyles: {
      style:{
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 180,
        marginTop:50,
        marginLeft:-18,
        top:54,
      },
    },
  };

  const confirmDelete = (selected) => {
    const inquiryList= queryResult.filter((inq)=>(selected.includes(inq.id))).map((b)=>b.inquiryId);
    const payload={
      inquiry:inquiryList,
      booking:selected,
    };
    // console.log(payload);
    dispatch(DeleteInquiry(payload));
    setDeleteInquiry([]);
    setClearCheckBoxes(!clearCheckBoxes);
  }
  const handleDelete=(selected)=>{
    const deletableGuests = queryResult.filter(inquiry => selected.indexOf(inquiry.id) >= 0 && inquiry.status === "Booked");
    if(deletableGuests.length){
      setDeleteInquiry(selected);
      setConfirmDel(true);
    } else {
      confirmDelete(selected);
    }
  }

  const handleDownload=(e,eType,sheetName)=>{
    e.preventDefault();
    downloadExcel(eType,sheetName,queryResult)
  }

  const handleHistoricData=(e)=>{
    e.preventDefault();
    const payload = {
      dateFrom: dateFrom ? moment(dateFrom).utc(true).format('YYYY-MM-DD') : "",
      dateTo: dateTo ? moment(dateTo).utc(true).format('YYYY-MM-DD') : "",
      rentalId: selectedRentals.map(value => value),
      paymentStatus: paymentStatus.filter(item => selectedPaymentStatus.includes(item.value)).map(value => value.label),
      status: bookingStatus.filter(item => selectedBookingStatus.includes(item.value)).map(value => value.label),
      guestsId: selectedGuests.map(value => value)
    }
    dispatch(downloadHistoricData(payload));
    setIsDownload(true);
  }
  
  const getPlaceholder = (items, selectedItems) => {
    let out = [];
    for (let i = 0; i < selectedItems.length; i++) {
      const item = items.find(item => item.value === selectedItems[i])["label"];
      out.push(item);
    }
    return out.length > 0 ? out.join(", ") : "";
  };


  return (
    <MuiThemeProvider theme={myTheme}>
    <Loading loadingStatus={inquiryStatus.loading}/>
    <div className={`${styles.wrapper}`} ref={wrapperRef} >
        <div className={`p-2 ${styles.header}`} ref={ref}>
          <div className={styles.first_col}>
            
            <div className={`${styles.btn_group_1}`}>
              <div 
                ref={mainWrapper}
                onClick={(e) => setShowHeader(!showHeader)}
                className={`${styles.filter_filter_name}`}
              >
                <div className={styles.btnWrapper}>
                  <div className={`${styles.filterName} ${styles.downloadFilter}`}>
                    <i className={`fa fa-filter ${styles.filter_icon}`} />{" "}
                    <span className={styles.filter_title_header}>Filter</span>
                  </div>
                  <div className={styles.filterDrop}>
                    <i className={`fa fa-caret-down`}/>
                  </div>
                </div>
              </div>
              { showHeader &&
                <button className={`${styles.btn_clear_filter}`} onClick={clearFilter}>
                  Clear Filter
                </button>
              }
            </div>
            
            <div className={`${styles.dropdown}`} onClick={()=>setActive(!dropActive)} ref={dropWrapper}>
              <div className={`${styles.filter_filter_name}`} >
                <div className={styles.btnWrapper}>
                  <div className={styles.filterName}>
                    <span className={styles.filter_title_header}>Download</span>
                  </div>
                  <div className={styles.filterDrop} onClick={()=>setSelection(!selectbar)}>
                    <i className={`fa fa-caret-down`}/>
                  </div>
                </div>
              </div>
              <div className={dropActive?`${styles.dropdownContentActive}`:`${styles.dropdownContent}`}>
                <a href="" onClick={(e)=>handleDownload(e,"inquiry","Reservations")}>{"Excel reservation list"}</a>
                <a href="" onClick={(e)=>handleDownload(e,"guest","Reservation_Guests")}>{"Excel reservations and guest list"}</a>
                <a href="" onClick={handleHistoricData}>{"Download historic data"}</a>
              </div>
            </div>
            
          </div>
          { showHeader &&
            <div className={`${styles.secound_col}`}>

              <section className={`d-sm-flex ${styles.input_section}`}>
                
                <InputGroup className={`m-0 p-0 mb-2 mb-sm-0 mt-2 col-12 col-sm-4 ${styles.input_group}`}>
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    className={`p-0 col-5 ${classes.inputLabel}`}
                  >
                    Rental
                  </InputLabel>
                  <SearchableSelect
                    items={rentals}
                    selectedItems={selectedRentals}
                    setSelectedItems={setSelectedRentals}
                    placeholder={getPlaceholder(rentals, selectedRentals)}
                    className={`m-0 p-0 col-7 ${styles.MuiSelect}`}
                  />
                </InputGroup>
                
                <div className={`m-0 p-0 mb-2 mb-sm-0 col-12 col-sm-8 ${styles.dateInputGroup}`}>
                  <InputGroup className={`${styles.input_group}`}>
                    <InputLabel id="demo-mutiple-checkbox-label" className={`p-0 col-5 col-md-3 ${classes.inputLabel}`}>Date from</InputLabel>
                    <CustomDate
                        id="date"
                        type="date"
                        value={dateFrom}
                        handleChange={(date) => setDateFrom(date)}
                        customClass={`m-0 pl-2 col-lg-8 ${styles.MuiSelect}`}
                    />
                  </InputGroup>

                  <InputGroup className={`${styles.input_group}`}>
                    <InputLabel id="demo-mutiple-checkbox-label" className={`p-0 col-5 col-md-3 ${classes.inputLabel}`}>Date to</InputLabel>
                    <CustomDate
                        id="date"
                        type="date"
                        value={dateTo}
                        handleChange={(date) => setDateTo(date)}
                        customClass={`m-0 pl-2 col-lg-8 ${styles.MuiSelect}`}
                    />
                  </InputGroup>
                </div>

              </section>

              <section className={`mb-3 d-sm-flex ${styles.input_section}`}>
              
                <InputGroup className={`m-0 p-0 mb-3 mb-sm-0 mt-2 col-12 col-sm-4 ${styles.input_group}`}>
                  <InputLabel
                    id="demo-multiple-checkbox-label"
                    className={`p-0 col-5 ${classes.inputLabel}`}
                  >
                    Payment status
                  </InputLabel>
                  <SearchableSelect
                    items={paymentStatus}
                    selectedItems={selectedPaymentStatus}
                    setSelectedItems={setSelectedPaymentStatus}
                    placeholder={getPlaceholder(paymentStatus, selectedPaymentStatus)}
                    className={`m-0 p-0 col-7 ${styles.MuiSelect}`}
                  />
                </InputGroup>
                
                <div className={`m-0 p-0 col-12 col-sm-8 d-sm-flex`}>
                  <InputGroup className={`mb-3 mb-sm-0 ${styles.input_group}`}>
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      className={`p-0 col-5 col-md-3 ${classes.inputLabel}`}
                    >
                      Status
                    </InputLabel>
                    <SearchableSelect
                      items={bookingStatus}
                      selectedItems={selectedBookingStatus}
                      setSelectedItems={setSelectedBookingStatus}
                      placeholder={getPlaceholder(bookingStatus, selectedBookingStatus)}
                      className={`m-0 p-0 col-6 ${styles.MuiSelect}`}
                    />
                  </InputGroup>

                  <InputGroup className={`${styles.input_group}`}>
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      className={`p-0 col-5 col-md-3 ${classes.inputLabel}`}
                    >
                      Guest
                    </InputLabel>
                    <SearchableSelect
                      items={guests}
                      selectedItems={selectedGuests}
                      setSelectedItems={setSelectedGuests}
                      placeholder={getPlaceholder(guests, selectedGuests)}
                      className={`m-0 p-0 col-6 ${styles.MuiSelect}`}
                    />
                  </InputGroup>
                </div>

              </section>
              
            </div>
          }
       </div>
       <div className={classes.mainContainer}>
      {!inquiryStatus.loading && (
          <Container fluid>
            <Row className="d-flex justify-content-center">
              <Col xs={12} md={12} className={`pt-3`}>
               <Table
                  rows={customClassification(queryResult, order, orderBy)}
                  cols={cols}
                  dateFormat={dateDisplay.toUpperCase()}
                  heading={"Reservations"}
                  handleDelete={handleDelete}
                  clearCheckBoxes={clearCheckBoxes}
                  order={order}
                  setOrder={setOrder}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
               />
              </Col>
            </Row>
          </Container>
      )}
      </div>
    </div>
    <ConfirmMessage
          show={confirmDel}
          onHide={() => {
            setConfirmDel(false);
            setDeleteInquiry([]);
          }}
          confirmHeader={`Warning: this action will delete a calendar reservation`}
          confirmBody={<>
            <span>{"Reservations with the 'booked' status are active bookings that are shown on your calendar."}</span>
            <br/>
            <span>{"Deleting the selected reservation will result in the deletion of the calendar booking. This action can not be undone."}</span>
            <br/>
            <br/>
            <span>{"Are you sure you want to proceed?"}</span>
          </>}
          onConfirmAct={()=> {
            confirmDelete(deleteInquiry);
              setConfirmDel(false);
          }}
          confirmDelete={true}
      />
    </MuiThemeProvider>
  );
}

export default Inquiry;
