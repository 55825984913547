import React, { useState, useEffect } from "react";
import {
  withStyles,
  makeStyles,
  MuiThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableFooter,
  TableRow,
  TableSortLabel,
  Paper,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import styles from "./table.module.css";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#F0F0F0',
    color: '#555',
    fontWeight: 550,
    lineHeight: '1.5rem',
  },
  root: {
    fontFamily: `'Montserrat','roboto'`,
    padding: '1em 0.4em',
    margin: 0,
  },
}))(TableCell);

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.primary.light,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
    fontWeight: "600",
  },
}));

const myTheme = createTheme({
  palette: {
    primary: {
      main: '#439a86',
      contrastText: '#fff',
    },
  },
});

function TableData(props) {

  const classes = useStyles();

  const {
    handleDelete,
    order,
    setOrder,
    orderBy,
    setOrderBy,
  } = props;

  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);

  const rowsPerPage = 25;
  const queryResult = props.rows;
  const totalPage = Math.ceil(queryResult.length / rowsPerPage);
  const showNavigateBeforeIcon = page > 0;
  const showNavigateNextIcon = !(page >= ((queryResult.length / rowsPerPage) - 1));
  const [zrows, setZrows] = useState(queryResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));

  useEffect(() => {
    setZrows(queryResult.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage));
  }, [page, queryResult]);

  const navigateBeforeFunc = () => {
    if (showNavigateBeforeIcon) {
      setPage(page-1);
    };
  };
  
  const navigateNextFunc = () => {
    if (showNavigateNextIcon) {
      setPage(page+1);
    };
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = zrows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
      <Toolbar
        className={classes.root}
      >
        {numSelected > 0 ? (
          <Typography
            variant="inherit"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            variant="inherit"
            component="div"
          >
            {props.heading}
          </Typography>
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <DeleteIcon onClick={() => handleDelete(selected)} />
            </IconButton>
          </Tooltip>
        ) : null}
      </Toolbar>
    );
  };

  return (
    <MuiThemeProvider theme={myTheme}>
      {props.heading && (
        <EnhancedTableToolbar
          numSelected={selected.length}
          heading={props.heading}
        />
      )}
      <TableContainer component={Paper}>
        <Table aria-label="caption table" {...props.tableProps}>
          <TableHead>
            <TableRow>
              {props.heading && (
                <StyledTableCell padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 && selected.length < zrows.length
                    }
                    checked={
                      zrows.length > 0 &&
                      selected.length === zrows.length
                    }
                    onChange={handleSelectAllClick}
                    color="primary"
                    inputProps={{ "aria-label": "select all desserts" }}
                    style={myTheme.palette.primary}
                  />
                </StyledTableCell>
              )}
              {props.cols.map((col, index) => (
                <StyledTableCell
                  align="left"
                  key={index}
                >
                  {col.key !== "" ? (
                    <span className={styles.wrapFieldTh}>
                      <TableSortLabel
                        active={orderBy === col.key}
                        direction={orderBy === col.key ? order : "asc"}
                        onClick={() => handleRequestSort(col.key)}
                      >
                        {col.lable}
                        {orderBy === col.key ? (
                          <span className={classes.visuallyHidden}>
                            {order === "desc"
                              ? "sorted descending"
                              : "sorted ascending"}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    </span>
                  ) : (
                    <span className={styles.wrapFieldTh}>
                      {col.lable}
                    </span>
                  )}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {zrows.length !== 0 && zrows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const rowStyle =
                  props.isViewRentals && index % 2 === 1
                    ? { backgroundColor: "#F0F0F0" }
                    : null;
                return (
                  <TableRow
                    key={row.id}
                    aria-checked={isItemSelected}
                    style={rowStyle}
                  >
                    {props.heading && (
                      <StyledTableCell>
                        <Checkbox
                          checked={isItemSelected}
                          color="primary"
                          inputProps={{ "aria-labelledby": row.id }}
                          size={"small"}
                          onClick={(event) => handleClick(event, row.id)}
                        />
                      </StyledTableCell>
                    )}
                    {props.cols.map((field) => (
                      <StyledTableCell align="left" key={field.key}>
                        <span className={styles.wrapField}>
                          {field.lable === "Actions" ? (
                            <div className={styles.iconContainer}>
                              {props.onEdit && (
                                  <span className={styles.iconBox}>
                                    <i
                                      className={`${styles.editIcon} fa fa-pencil-square-o`}
                                      onClick={() => props.onEdit(row)}
                                    />
                                  </span>
                                )}
                                {props.onDelete ? (
                                  props.confirmDeleteDialog ? (
                                    <span className={styles.iconBox}>
                                      <i
                                        className={`${styles.icon} fa fa-trash`}
                                        onClick={(e) =>
                                          props.handleConfirmDialog(e, row)
                                        }
                                        aria-describedby="popover-1"
                                      />
                                      {props.confirmDeleteDialog()}
                                    </span>
                                  ) : (
                                    <span className={styles.iconBox}>
                                      <i
                                        className={`${styles.icon} fa fa-trash`}
                                        onClick={() => props.onDelete(row)}
                                      />
                                    </span>
                                  )
                                ) : null}
                                {props.onDownload && (
                                  <span className={styles.iconBox}>
                                    <i
                                      className={`${styles.icon} ${styles.iconDownload} fas fa-download`}
                                      onClick={() => props.onDownload(row)}
                                    />
                                  </span>
                                )}
                                {props.onSendGuest && (
                                  <span className={styles.iconBox}>
                                    <i
                                      className={`${styles.icon} ${styles.iconSend} fas fa-mail-bulk`}
                                      onClick={() => props.onSendGuest(row)}
                                    />
                                  </span>
                                )}
                              </div>
                          ) : (
                            <>
                              {field.key === "color" ? (
                                <div
                                  style={{ backgroundColor: row[field.key] }}
                                  className={styles.color_box}
                                />
                              ) : (
                                <span>
                                  {field.key === "name" &&
                                  row.parentRentalId !== null ? (
                                    <span
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <SubdirectoryArrowRightIcon
                                        fontSize="small"
                                         style={{ color: "#9e9e9e" }} 
                                      
                                      />
                                      {row.name}
                                    </span>
                                  ) : (
                                    <span>
                                      {field.isDate
                                        ? `${row[field.key]}`
                                        : `${
                                            row[field.key] ||
                                            row[field.key] === 0
                                              ? row[field.key] === 0
                                                ? "0"
                                                : row[field.key]
                                              : "-"
                                          }`}
                                    </span>
                                  )}
                                </span>
                              )}
                            </>
                          )}
                        </span>
                      </StyledTableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
          {zrows && zrows.length === 0 && (
            <caption>No data found.</caption>
          )}
        </Table>
      </TableContainer>
      {(totalPage > 1) && <TableFooter className="d-flex justify-content-center">
        <div className={styles.navigateContainer}>
          <NavigateBeforeIcon
            style={{
              color: showNavigateBeforeIcon ? "grey" : "transparent",
              cursor: showNavigateBeforeIcon ? "pointer" : "default",
            }}
            onClick={navigateBeforeFunc}
          />
          <div>Page <span>{page + 1}</span> of <span>{totalPage}</span></div>
          <NavigateNextIcon
            style={{
              color: showNavigateNextIcon ? "grey" : "transparent",
              cursor: showNavigateNextIcon ? "pointer" : "default",
            }}
            onClick={navigateNextFunc}
          />
        </div>
      </TableFooter>}
    </MuiThemeProvider>
  );
}

export default TableData;



