import React,{ useEffect, useState } from 'react';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Modal } from 'react-bootstrap';
import styles from '../../../Rentals/rentals.module.css';
import styles2 from './viewBooking.module.css';
import {useSelector} from "react-redux";
import {tConvert} from '../../../../../../common/functions/utils';
import { useDispatch } from 'react-redux';
import {zeroPad} from '../../../../../../common/functions/utils'
import { deleteBooking } from '../../../../../../general_redux/calendar/actions';
import DeleteConfirm from './Components/DeleteConfirm/DeleteConfirm';

import ColorsBox from './Components/ColorsBox';

function ViewBooking(props) {
  const guestDetail = useSelector(({guests})=>guests && guests.guestByBooking
      && guests.guestByBooking.length!==0 && guests.guestByBooking[0] );
  const setting=useSelector(({generalSetting})=>(generalSetting && generalSetting.setting));
  const guestByBooking=useSelector(({guests})=>(guests && guests.guestByBooking));

    const data = useSelector(({ calendar }) => {
        const bookingId = props.data?.id;
        return calendar && bookingId && calendar.allBooking?.find(({ id }) => id === bookingId);
    });

    const rentalDetail = useSelector(({ rentals }) => {
        const rentalId = props.data?.rentalId;
        return rentals && rentalId && rentals.rentals.find(({ id })=> id === rentalId);
    });

  const dispatch = useDispatch();
  const [delBooking,setDelBooking]=useState(false);
  const [colorsBoxAnchorEl, setColorsBoxAnchorEl] = useState(null);
  const [deleteConfirmAnchorEl, setDeleteConfirmAnchorEl] = useState(null);

  const deleteConfirmOpenPopover = (e) => {
    setDeleteConfirmAnchorEl( deleteConfirmAnchorEl ? null : e.currentTarget);
  };
  
  const deleteConfirmClosePopover = () => {
    setDeleteConfirmAnchorEl(null);
  };

  const colorsBoxOpenPopover = (e) => {
    setColorsBoxAnchorEl( colorsBoxAnchorEl ? null : e.currentTarget);
  };
  
  const colorsBoxClosePopover = () => {
    setColorsBoxAnchorEl(null);
  };

  const [inputColor,setInputColor]=useState(data?.color);

  useEffect(() => {
    setInputColor(data?.color);
  }, [data]);

  const handleDelete=()=>{
    dispatch(deleteBooking(data.id));
    deleteConfirmClosePopover();
    setDelBooking(false);
    props.onHide();
  }

  const guestName = guestDetail ? guestDetail.name : data?.guestName;

  const ota = (() => {
    const source = data?.source;
    const OTAs = ['AIRBNB','BEEHAZ','AGODA','BOKKINGCOM','VRBO','OTHERS'];
    const ota = source && OTAs.find(item => item === source.toUpperCase());

    if (!ota) {
        return '';
    };
    if (ota === 'BOKKINGCOM') { 
        return 'Booking.com';
    };
    if (ota === 'VRBO') { 
        return 'VRBO';
    };
    return ota.charAt(0).toUpperCase() + ota.slice(1).toLowerCase();

  })();
  

  return (
      <>
          <Modal
              show={props.show}
              size="sm"
              aria-labelledby="contained-modal-title-vcenter"
              centered
              onHide={()=>{
                  setDelBooking(false);
                  props.onHide();
              }
          }>
            { data && (
            <>
              <Modal.Header className={data.bookingType === 'channelBooking' && styles2.modalFooter} closeButton>
                { data.bookingType === 'channelBooking' ?
                    <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>{`${(ota && guestName) ? `${ota} ${guestName}` : `${ota} Guest`}: Reserved`}</Modal.Title>
                    :
                    <Modal.Title id="contained-modal-title-vcenter" className={styles.modalTitle}>{guestDetail.name}</Modal.Title>
                }
              </Modal.Header>
              <Modal.Body className={data.bookingType === 'channelBooking' && styles2.modalFooter}>
                  {data && data.length !== 0 && (
                        <div className={styles2.mainContainer}>
                            {(data.bookingType === 'channelBooking' && ota)  &&
                                <>
                                    <div>
                                        <div style={{color: '#ffd900'}}>
                                            Channel Reservation: {ota}
                                        </div>
                                        { data?.reservationLink &&
                                            <div>
                                                Reservation link: <br />
                                                {data?.reservationLink}
                                            </div>
                                        }
                                    </div>
                                    <br />
                                </>
                            }
                          <div className={styles2.sideText}>
                            { (guestDetail.phoneNo !== "") ?
                                <a href={`https://api.whatsapp.com/send?phone=${guestDetail?.phoneNo?.replace(/\D+/g, "")}`} target={"_blank"} rel={"noopener noreferrer"} style={{textDecoration:"none",color:"grey"}}>
                                    <span><i className={`fab fa-whatsapp ${styles.smallIconWA}`}/>{guestDetail.phoneNo}</span>
                                </a>
                            :
                                "Phone number unavailable"
                            }
                          </div>
                          <div className={styles2.sideText}>
                            { (guestDetail.emailId !== "") ?
                                <a href={`mailto: ${guestDetail.emailId}`}target={"_blank"} rel={"noopener noreferrer"} style={{textDecoration:"none",color:"grey"}}>
                                    <span><i className={`far fa-envelope ${styles.smallIcon}`}/>{guestDetail.emailId}</span>
                                </a>
                            :
                                "Email unavailable"
                            }
                          </div>
                          <br />
                          <div className={styles2.sideText}>{`Booking Number: ${new Date().getUTCFullYear()}${zeroPad(data.id, 4)}`}</div>
                          <div className={styles2.mainText}>{`Start date : ${data.start_time.format('Do MMMM, YYYY')}`}</div>
                          <div className={styles2.mainText}>{`End date : ${data.end_time.format('Do MMMM, YYYY')}`}</div>
                          <div className={styles2.sideText}>{`${data.nights} Nights `}</div>
                          <div className={styles2.sideText}>{`Check-in time: ${(setting.timeDisplay==="AM_PM")?
                              tConvert(data.checkInTime):data.checkInTime}`}</div>
                          <div className={styles2.sideText}>{`Check-out time:  ${(setting.timeDisplay === "AM_PM") ?
                              tConvert(data.checkOutTime) : data.checkOutTime}`} </div>
                          <div className={styles2.mainText}>{`Total guests : ${Number(data.noOfChildren)+Number(data.noOfAdults)}`}</div>
                          <div className={styles2.sideText}>{`Adults ${data.noOfAdults} | Children ${data.noOfChildren} | Babies ${data.noOfBabies}`}</div>
                          <div className={styles2.mainText}>{`Price : ${data.price} ${getSymbolFromCurrency(rentalDetail.currency)}`}</div>
                          <div className={styles2.sideText}>{`Status : ${data.paymentStatus}`}</div>
                          <div className={styles2.mainText}>
                              <div>{`Colour :`}</div>
                                <div
                                    className={styles2.containerBox}
                                    style={{background:`${inputColor}`}}
                                    key={data.color}
                                    onClick={colorsBoxOpenPopover}
                                    aria-describedby="popover-color-box"
                                />
                                <ColorsBox
                                    booking={data}
                                    id={"popover-color-box"}
                                    open={Boolean(colorsBoxAnchorEl)}
                                    anchorEl={colorsBoxAnchorEl}
                                    onClose={colorsBoxClosePopover}
                                />
                          </div>
                          <div className={styles2.mininote}>
                              <span>{`${data.miniNotes}`}</span>
                          </div>
                          {(data.bookingType==="weekly" || data.bookingType==="monthly") && (
                            <div className={`${styles2.mininote} ${styles2.special} `}>
                                <span>{`Fixed term reservation`}</span>
                            </div>
                          )}
                      </div>
                  )}
              </Modal.Body>
              {(delBooking === true) ? (
                  <div className={`${data.bookingType === 'channelBooking' && styles2.modalFooter} p-2 d-flex justify-content-center`}>
                      <button type="submit" style={{backgroundColor:"#dc3545",width:'145px'}}
                              className={styles.btnClass} onClick={handleDelete}>
                          Confirm Delete
                          <span>
                    <i className={`fa fa-check-circle ${styles2.icons}`}/>
                  </span>
                      </button>
                      <button  type="submit" style={{backgroundColor:"#439a86"}}
                               className={styles.btnClass} onClick={()=>setDelBooking(false)}>
                          Cancel
                          <span>
                    <i className={`fa fa-undo ${styles2.icons}`}/>
                  </span>
                      </button>
                  </div>
              ):(
                  <div className={`${data.bookingType === 'channelBooking' && styles2.modalFooter} p-2 d-flex justify-content-center`}>
                      <button 
                        aria-describedby="popover-del-confirm"
                        type="submit"
                        style={{backgroundColor:"#dc3545"}}
                        className={styles.btnClass} 
                        onClick={(e) => {
                            if (data.bookingType === 'channelBooking') {
                                deleteConfirmOpenPopover(e);
                            } else {
                                setDelBooking(true);
                            }
                        }}>

                          Delete
                      </button>
                      <button  
                        type="submit"
                        style={{backgroundColor:"#439a86"}}
                        className={styles.btnClass}
                        onClick={props.onEdit}
                        disabled={guestByBooking && guestByBooking.length === 0}>
                        
                        Edit
                      </button>
                  </div>
              )}
                <DeleteConfirm
                    id={"popover-del-confirm"}
                    open={Boolean(deleteConfirmAnchorEl)}
                    anchorEl={deleteConfirmAnchorEl}
                    onClose={deleteConfirmClosePopover}
                    onConfirmAct={handleDelete}
                />
            </>
            )}
          </Modal>
      </>

  );
}
export default ViewBooking;
