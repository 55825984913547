import { toast } from 'react-toastify';
import {
  CLEAR_RENTAL_ADD,
  LOAD_GROUP,
  ADD_GROUP,
  DELETE_GROUP,
  LOAD_RENTAL,
  ADD_RENTAL,
  DELETE_RENTAL,
  EDIT_RENTAL,
  UPDATE_GROUP,
  LOAD_PROPERTY,
  ADD_PROPERTY,
  EDIT_PROPERTY,
  DELETE_PROPERTY,
  GET_COORDINATES,
  CLEAR_COORDINATES,
  UPDATE_RENTAL_DATA_INHERITED,
} from './types';
import { actionForState } from '../../common/functions/utils';
toast.configure();

const INITIAL_STATE = {
  groups: [],
  rentals: [],
  properties: [],
  coordinates: null,
  lastAddedRentalID: null,
  action: {
    loading: false,
    success: undefined,
  },
  actionForGroup: {
    loading: false,
    success: false,
    error: false,
  },
  actionForRental: {
    loading: false,
    success: false,
    error: false,
  },
  actionAddGroup: {
    loading: false,
    success: false,
    error: false,
  },
  actionDeleteGroup: {
    loading: false,
    success: false,
    error: false,
  },
  actionAddRental: {
    loading: false,
    success: false,
    error: false,
  },
  actionDeleteRental: {
    loading: false,
    success: false,
    error: false,
  },
  actionEditRental: {
    loading: false,
    success: false,
    error: false,
  },
  actionEditGroup: {
    loading: false,
    success: false,
    error: false,
  },
  actionForProperty: {
    loading: false,
    success: false,
    error: false,
  },
  actionEditProperty: {
    loading: false,
    success: false,
    error: false,
  },
  actionAddProperty: {
    loading: false,
    success: false,
    error: false,
  },
  actionDeleteProperty: {
    loading: false,
    success: false,
    error: false,
  },
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_GROUP.pending:
      return {
        ...state,
        actionAddGroup: actionForState(state.action, 'pending'),
      };
    case ADD_GROUP.success:
      toast.success("Group has been saved successfully")
      return {
        ...state,
        groups: state.groups.concat(action.payload.group),
        actionAddGroup: actionForState(state.action, 'success'),
      };
    case ADD_GROUP.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionAddGroup: actionForState(state.action, 'error', action.payload.message),
      };
    case LOAD_GROUP.pending:
      return {
        ...state,
        actionForGroup: actionForState(state.action, 'pending'),
      };
    case LOAD_GROUP.success:
      return {
        ...state,
        actionForGroup: actionForState(state.action, 'success'),
        groups: action.payload.groups,
      };
    case LOAD_GROUP.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionForGroup: actionForState(state.action, 'error', action.payload.message),
      };
    case DELETE_GROUP.pending:
      return {
        ...state,
        actionDeleteGroup: actionForState(state.action, 'pending'),
      };
    case DELETE_GROUP.success:
      toast.success('Group successfully deleted.');
      return {
        ...state,
        groups: state.groups.filter((row) => row.id !== Number(action.payload.id)),
        actionDeleteGroup: actionForState(state.action, 'success'),
      };
    case DELETE_GROUP.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionDeleteGroup: actionForState(state.action, 'error', action.payload.message),
      };
    case UPDATE_GROUP.pending:
      return {
        ...state,
        actionEditGroup: actionForState(state.action, 'pending'),
      };
    case UPDATE_GROUP.success:
      toast.success('Group edited successfully!');
      const indx = state.groups.findIndex((row) => row.id === action.payload.data.id);
      let newGrp = [...state.groups];
      newGrp[indx] = action.payload.data;
      return {
        ...state,
        groups: newGrp,
        actionEditGroup: actionForState(state.action, 'success'),
      };
    case UPDATE_GROUP.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionEditGroup: actionForState(state.action, 'error', action.payload.message),
      };
    case LOAD_RENTAL.pending:
      return {
        ...state,
        actionForRental: actionForState(state.action, 'pending'),
      };
    case LOAD_RENTAL.success:
      return {
        ...state,
        actionForRental: actionForState(state.action, 'success'),
        rentals: action.payload.rentals,
      };
    case LOAD_RENTAL.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionForRental: actionForState(state.action, 'error', action.payload.message),
      };
    case ADD_RENTAL.pending:
      return {
        ...state,
        actionAddRental: actionForState(state.action, 'pending'),
      };
    case ADD_RENTAL.success:
      toast.success('Rental was added successfully!');
      return {
        ...state,
        rentals: state.rentals.concat(action.payload.data),
        actionAddRental: actionForState(state.action, 'success'),
        lastAddedRentalID: action.payload.data.id,
      };
    case ADD_RENTAL.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionAddRental: actionForState(state.action, 'error', action.payload.message),
      };
    case DELETE_RENTAL.pending:
      return {
        ...state,
        actionDeleteRental: actionForState(state.action, 'pending'),
      };
    case DELETE_RENTAL.success:
      toast.success('Rental deleted successfully!');
      
      let filterRentals = [];
      if (action.payload.data.deleteChildrenRentals) {
        filterRentals = state.rentals.filter((rental) => {
          if (![rental.id, rental.parentRentalId].includes(action.payload.data.rentalId)) {
            return rental;
          }
        });
      } else {
        state.rentals.forEach((rental) => {
          if (rental.id !== action.payload.data.rentalId) {
            if (rental.parentRentalId === action.payload.data.rentalId) {
              filterRentals.push({
                ...rental,
                parentRentalId: null,
              });
            } else {
              filterRentals.push(rental);
            };
          };
        });
      };

      return {
        ...state,
        rentals: filterRentals,
        actionDeleteRental: actionForState(state.action, 'success'),
      };
    case DELETE_RENTAL.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionDeleteRental: actionForState(state.action, 'error', action.payload.message),
      };
    case EDIT_RENTAL.pending:
      return {
        ...state,
        actionEditRental: actionForState(state.action, 'pending'),
      };
    case EDIT_RENTAL.success:
      toast.success('Rental edited successfully!');
      let rentalsArrayData = [...state.rentals];
      rentalsArrayData.forEach((oldRental, index) => {
        const updateRental = action.payload.data.find(newRental => newRental.id === oldRental.id);
        if (updateRental) {
          // update the property ID of child rentals if applicable.
          if (oldRental.propertyId !== updateRental.propertyId && oldRental.parentRentalId === null) {
            rentalsArrayData = rentalsArrayData.map(sonRental => sonRental.parentRentalId === oldRental.id ? { ...sonRental, propertyId: updateRental.propertyId } : sonRental);
          };
          
          // // update the group ID of children, parent and brothers rentals if applicable.
          // if (oldRental.groupId !== updateRental.groupId) {
          //   rentalsArrayData = rentalsArrayData.map(rental => {
          //     const updateParent = rental.id === oldRental.parentRentalId;
          //     const updateChildren = rental.parentRentalId === oldRental.id;
          //     const updateBrothers = (rental.parentRentalId === oldRental.parentRentalId && oldRental.parentRentalId !== null);
          //     return (updateParent || updateChildren || updateBrothers) ? { ...rental, groupId: updateRental.groupId } : rental;
          //   });
          // };

          // update rental.
          rentalsArrayData[index] = updateRental;
        }
    });
      return {
        ...state,
        rentals: rentalsArrayData,
        actionEditRental: actionForState(state.action, 'success'),
      };
    case EDIT_RENTAL.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionEditRental: actionForState(state.action, 'error', action.payload.message),
      };
    case UPDATE_RENTAL_DATA_INHERITED:
      const property = action.payload;
      property['id']= typeof property.id === 'number' ? [property.id] : property.id;

      const updatedRentals = state.rentals.map(rental => {
        if (property.id.includes(rental.propertyId)) {
            const updatedRental = { ...rental };
            Object.keys(property).forEach(field => {
                if (!['id', 'name'].includes(field)) {
                    updatedRental[field] = property[field];
                }
            });
            return updatedRental;
        }
        return rental;
        });

      return {
        ...state,
        rentals: updatedRentals,
      };

    case CLEAR_RENTAL_ADD:
      return {
        ...state,
        lastAddedRentalID: null,
        actionAddRental: INITIAL_STATE.actionAddRental
      };
    default:
      return state;

      case LOAD_PROPERTY.pending:
        return {
          ...state,
          actionForProperty: actionForState(state.action, 'pending'),
        };
      case LOAD_PROPERTY.success:
        var _properties = action.payload.data.map(_property => _property?.name === 'Default'
          ? {..._property, name: 'Property 1'}
          : _property
        );

        return {
          ...state,
          actionForProperty: actionForState(state.action, 'success'),
          properties: _properties,
        };
      case LOAD_PROPERTY.error:
        toast.error(action.payload.message);

        return {
          ...state,
          actionForProperty: actionForState(state.action, 'error', action.payload.message),
        };  
    case EDIT_PROPERTY.pending:
      return {
        ...state,
        actionEditProperty: actionForState(state.action, 'pending'),
      };
    case EDIT_PROPERTY.success:
      toast.success('Property edited successfully!');
      let propertyArrayData = [...state.properties];
      propertyArrayData.forEach((oldProperty, index) => {
        const editProperty = action.payload.data.find(newProperty => newProperty.id === oldProperty.id);
        if (editProperty) {
          propertyArrayData[index] = editProperty.name === 'Default'
          ? {...editProperty, name: 'Property 1'}
          : editProperty;
        }
    });
      return {
        ...state,
        properties: propertyArrayData,
        actionEditProperty: actionForState(state.action, 'success'),
      };
    case EDIT_PROPERTY.error:
      toast.error(action.payload.message);
      return {
        ...state,
        actionEditProperty: actionForState(state.action, 'error', action.payload.message),
      };

      case ADD_PROPERTY.pending:
        return {
          ...state,
          actionAddProperty: actionForState(state.action, 'pending'),
        };
      case ADD_PROPERTY.success:
        toast.success('Property created successfully!');

        return {
          ...state,
          properties: state.properties.concat(action.payload.data),
          actionAddProperty: actionForState(state.action, 'success'),
        };
      case ADD_PROPERTY.error:
        toast.error(action.payload.message);
        return {
          ...state,
          actionAddProperty: actionForState(state.action, 'error', action.payload.message),
        };
      case DELETE_PROPERTY.pending:
        return {
          ...state,
          actionDeleteProperty: actionForState(state.action, 'pending'),
        };
      case DELETE_PROPERTY.success:
        toast.success('Property deleted successfully!');
        return {
          ...state,
          rentals: state.rentals.filter(rental => rental.propertyId !== Number(action.payload.id)),
          properties: state.properties.filter(property => property.id !== Number(action.payload.id)),
          actionDeleteProperty: actionForState(state.action, 'success'),
        };
      case DELETE_PROPERTY.error:
        toast.error(action.payload.message);
        return {
          ...state,
          actionDeleteProperty: actionForState(state.action, 'error', action.payload.message),
        };
      
      case GET_COORDINATES.pending:
        return {
          ...state,
          actionDeleteProperty: actionForState(state.action, 'pending'),
        };
      case GET_COORDINATES.success:
        return {
          ...state,
          coordinates: action.payload.data,
          actionDeleteProperty: actionForState(state.action, 'success'),
        };
        case GET_COORDINATES.error:
          toast.error(action.payload.message);
          return {
            ...state,
            actionDeleteProperty: actionForState(state.action, 'error', action.payload.message),
          };
        case CLEAR_COORDINATES:
          return {
            ...state,
            coordinates: INITIAL_STATE.coordinates,
            actionDeleteProperty: actionForState(state.action, 'success'),
          };
  }
}
