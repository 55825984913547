import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import {Box, Popover} from "@material-ui/core";
import Loading from '../../../../../../components/loading';
import { customSort, customClassification } from "../../../../../../common/functions/utils";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import AddFee from './AddFee/AddFee';
import ExtraGuestFee from './AddFee/ExtraGuestFee'
import { addFee, deleteFee, editFee } from '../../../../../../general_redux/rates/actions';
import Table from '../../../../../../components/Table';
import styles from '../../rates.module.css';

function Fees() {

  const dispatch = useDispatch();

  const [addFeeModal, setAddFeeModal] = useState(false);
  const [extraGuestModal,setExtraGuestModal]=useState(false);
  const [edited, setEdited] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentDelete,setCurrentDelete] = useState(null);

  const cols = [
    { lable: 'Name', key: 'name' },
    { lable: 'Type', key: 'feeType' },
    { lable: 'Amount', key: 'amount' , numFormat:"1,000.00"},
    { lable: 'Modality', key: 'modality'},
    { lable: 'Actions', key: '' },
  ];

  const rentals = useSelector(({ rentals }) => rentals && rentals.rentals);
  const currency =useSelector(({user})=>user && user.user && user.user.currency);
  const feesList = useSelector(({ rates }) => rates && rates.feesList);
  const actionForGetFeeList = useSelector(({ rates }) => rates && rates.actionForGetFeeList);
  const actionForEditFee=useSelector(({rates})=> rates && rates.actionForEditFee);
  const actionForAddFee = useSelector(({rates})=>rates && rates.actionForAddFee);
  const actionForDeleteFee=useSelector(({rates})=>rates && rates.actionForDeleteFee);
  const actionForExtraGuest = useSelector(({rates}) => rates && rates.actionForExtraGuest);
  const [feeCategory,setFeeCategory]=useState([]);
  const extraGuestFeesList = useSelector(({ rates }) => rates && rates.rateList);

  useEffect(()=>{
    if(actionForGetFeeList.success || actionForEditFee.success || actionForAddFee.success || actionForDeleteFee.success|| actionForExtraGuest.success){
      const tmp=[];
      if(feesList){
        rentals.forEach((rental) => {
          const listItems = feesList.filter((fee) => fee.rentalId === rental.id);
          tmp[`${rental.id}`] = listItems;
        })
        setFeeCategory(tmp);
      }
    }
  },[actionForGetFeeList,actionForEditFee,actionForAddFee,actionForDeleteFee,actionForExtraGuest])

  const closeModal = (data) => {
    if (edited) {
      dispatch(editFee(data));
    } else {
      dispatch(addFee(data));
    }
    setEdited(null);
    setAddFeeModal(false);
  };

  const deleteRow = (id) => {
    dispatch(deleteFee(id));
  };

  const editRow = (val) => {

    const payload = {
      id: val.id,
      rentalId: val.rentalId,
      amount: val.amount,
      feeApplyAdults: val.feeApplyAdults,
      feeApplyChildren: val.feeApplyChildren,
      name: val.name,
      feeType: val.feeType,
      modality: val.modality,
    };
    
    // console.log(payload);
    setEdited(payload);
    setAddFeeModal(true);
  };

  const findCurrency = (id)=>{
    const tmp= rentals.filter((rental)=>rental.id===Number(id))
    if(tmp.length!==0){
        return getSymbolFromCurrency(tmp[0].currency);
    }else{
        return "";
    }
  };

  const renderGuestFee = (rentalId) => {
    const obj = extraGuestFeesList.find(item => item.rentalId === rentalId);
    const symbolFromCurrency = findCurrency(rentalId);

    let display = 'not added.';
    
    if (symbolFromCurrency) {
      if (obj?.usdPerGuest && obj?.extraChildFee && obj?.guestPerNight) {
        display = `${symbolFromCurrency} ${(obj?.usdPerGuest).toFixed(2)} per guest after ${obj?.guestPerNight} guests.`;
      
      } else if (obj?.usdPerGuest && !obj?.extraChildFee && obj?.guestPerNight) {
        display = `Adult fee: ${symbolFromCurrency} ${(obj?.usdPerGuest).toFixed(2)} per guest after ${obj?.guestPerNight} guests. Child fee: ${symbolFromCurrency} ${(obj?.usdPerChild).toFixed(2)} per child.`;
      
      } else if (!obj?.usdPerGuest && !obj?.extraChildFee && obj?.usdPerChild) {
        display = `Child fee: ${symbolFromCurrency} ${(obj?.usdPerChild).toFixed(2)} per child.`;
      };
    };

    return (
      <div className="my-2 d-flex align-items-center">
        <div className={styles.extraGuestLabel}>Extra guest fee:</div>
        <div className="ml-2">
          { display }
        </div>
      </div>
    )
  };

  const handleDelete=()=>{
    if(currentDelete && currentDelete.id){
      dispatch(deleteFee(currentDelete.id));
    }
    setAnchorEl(null);
    setCurrentDelete(null);
  };

  const handleConfirmDialog = (e,row) => {
    setCurrentDelete(row);
    setAnchorEl( anchorEl ? null : e.currentTarget);
  };

  const invoiceDeleteDialog = () => {
    return (
        <Popover
            id={"popover-1"}
            open={Boolean(anchorEl)}
            onClose={handleConfirmDialog}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: "center",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "center",
                horizontal: "right",
            }}
            PaperProps={{
                style: {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: 0,
                    display: "flex",
                },
            }}
        >
            <div className={styles.contentWrap}>
                <div className={styles.deleteTitle}>
                    Are you sure you want to delete this fee?
                </div>
                <div className="text-center m-1">
                    <button
                        onClick={() => handleDelete()}
                        className={styles.delConfirmBtn}
                    >
                        Delete
                    </button>
                    <button
                        onClick={handleConfirmDialog}
                        className={styles.cancelBtn}
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <Box
                className={styles.rightArrow}
            />
        </Popover>
    );
  };

  const [tableStates, setTableStates] = useState(
    rentals.length !== 0 && customSort(rentals, 'asc', 'name').reduce((acc, _, index) => {
      acc[index] = { order: 'asc', orderBy: 'name' };
      return acc;
    }, {})
  );

  const handleTableStateChange = (index, newState) => {
    setTableStates((prevState) => ({
      ...prevState,
      [index]: { ...prevState[index], ...newState }
    }));
  };

  const renderFeesTable = useMemo(() => {
    return (
      <div className="col-lg-10 col-xl-8 px-0 mb-3">
        {rentals.length !== 0 ? customSort(rentals, 'asc', 'name').map((rental, index) => {
          const { order, orderBy } = tableStates[index];

          return (
            <div key={index} className="mb-4">
              <div className='d-flex'>
                {rental.parentRentalId && 
                  <SubdirectoryArrowRightIcon
                      fontSize="small"
                      style={{ color: "#9e9e9e" }}
                  />
                }
                <div className={styles.taxHeader}>{rental.name}</div>
              </div>
              {renderGuestFee(rental.id)}
              <Table
                rows={customClassification(((feeCategory && feeCategory[rental.id]) ? feeCategory[rental.id] : []), order, orderBy)}
                cols={cols}
                onEdit={editRow}
                onDelete={(data) => deleteRow(data.id)}
                confirmDeleteDialog={invoiceDeleteDialog}
                handleConfirmDialog={handleConfirmDialog}
                order={order}
                orderBy={orderBy}
                setOrder={(newOrder) =>
                  handleTableStateChange(index, { order: newOrder })
                }
                setOrderBy={(newOrderBy) =>
                  handleTableStateChange(index, { orderBy: newOrderBy })
                }
              />
            </div>
        )})
          :
          <h5>No data to display</h5>}
      </div>
  )
  }, [dispatch, tableStates, rentals, feesList, feeCategory, anchorEl, actionForExtraGuest, extraGuestFeesList]);

  return (
    <>
      <Loading loadingStatus={actionForGetFeeList.loading || actionForExtraGuest.loading} />
      <AddFee
        show={addFeeModal}
        onHide={() => {
          setAddFeeModal(false);
          setEdited(null);
        }}
        onEdit={(data) => closeModal(data)}
        viewForEdit={edited}
        currency={currency}
      />
      <ExtraGuestFee
        show={extraGuestModal}
        onHide={() => setExtraGuestModal(false)}
        currency={currency}
      />
      <div className="py-3">
        <button className={styles.ratelistBtn} onClick={() => setAddFeeModal(true)}>
          Add Fee
        </button>
        <button className={styles.ratelistBtn} onClick={() => setExtraGuestModal(true)}>
          Extra Guest Fee
        </button>
      </div>
      {renderFeesTable}
    </>
  );
}

export default Fees;
